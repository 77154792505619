import React from "react";

function ExampleLogo() {
  return (
    <svg
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2937_8219)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6462 1.44531C12.3898 1.44531 10.2259 2.34165 8.63045 3.93714L0.931641 11.6359V15.1599C0.931641 17.6496 2.00108 19.8895 3.70565 21.4453C2.00108 23.0011 0.931641 25.241 0.931641 27.7307V31.2547L8.63045 38.9535C10.2259 40.549 12.3898 41.4453 14.6462 41.4453C17.1359 41.4453 19.3758 40.3759 20.9316 38.6713C22.4874 40.3759 24.7273 41.4453 27.217 41.4453C29.4734 41.4453 31.6373 40.549 33.2328 38.9535L40.9316 31.2547V27.7307C40.9316 25.241 39.8622 23.0011 38.1576 21.4453C39.8622 19.8895 40.9316 17.6496 40.9316 15.1599V11.6359L33.2328 3.93714C31.6373 2.34165 29.4734 1.44531 27.217 1.44531C24.7273 1.44531 22.4874 2.51475 20.9316 4.21932C19.3758 2.51475 17.1359 1.44531 14.6462 1.44531ZM26.6904 21.4453C26.5945 21.3578 26.5004 21.2679 26.4082 21.1757L20.9316 15.6991L15.455 21.1757C15.3628 21.2679 15.2687 21.3578 15.1728 21.4453C15.2687 21.5328 15.3628 21.6227 15.455 21.7149L20.9316 27.1915L26.4082 21.7149C26.5004 21.6227 26.5945 21.5328 26.6904 21.4453ZM23.1538 31.2547V32.9377C23.1538 35.1817 24.973 37.0009 27.217 37.0009C28.2946 37.0009 29.3281 36.5728 30.0901 35.8108L36.4872 29.4137V27.7307C36.4872 25.4867 34.668 23.6675 32.424 23.6675C31.3464 23.6675 30.3129 24.0956 29.5509 24.8576L23.1538 31.2547ZM18.7094 31.2547L12.3123 24.8576C11.5503 24.0956 10.5169 23.6675 9.43926 23.6675C7.19523 23.6675 5.37608 25.4867 5.37608 27.7307V29.4137L11.7731 35.8108C12.5351 36.5728 13.5686 37.0009 14.6462 37.0009C16.8902 37.0009 18.7094 35.1817 18.7094 32.9377V31.2547ZM18.7094 9.95293V11.6359L12.3123 18.033C11.5503 18.795 10.5169 19.2231 9.43926 19.2231C7.19523 19.2231 5.37608 17.4039 5.37608 15.1599V13.4769L11.7731 7.07983C12.5351 6.31784 13.5686 5.88976 14.6462 5.88976C16.8902 5.88976 18.7094 7.7089 18.7094 9.95293ZM29.5509 18.033L23.1538 11.6359V9.95293C23.1538 7.7089 24.973 5.88976 27.217 5.88976C28.2946 5.88976 29.3281 6.31784 30.0901 7.07983L36.4872 13.4769V15.1599C36.4872 17.4039 34.668 19.2231 32.424 19.2231C31.3464 19.2231 30.3129 18.795 29.5509 18.033Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2937_8219">
          <rect
            width="40"
            height="41"
            fill="white"
            transform="translate(0.931641 0.929688)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ExampleLogo;
