import React from "react";
import { AboutCardBody, AboutCardContainer, AboutCardTitle } from "../../assets/styles/styles";

export default function FeaturesCardRight(props) {
  const { imgUrl,title,details } = props;
  return (
    <div className="row p-2 p-lg-2 position-relative ">
      <div className="col-12 d-flex  px-5 px-md-3">
        <div className="col-1 d-none d-lg-flex"></div>
        <div
          className="col-10 col-md-7 col-lg-5 p-0 mb-5 "
          style={{
            borderRadius: "21px",
          }}
        >
          <img
            src={imgUrl}
            width="100%"
            height="auto"
            className="shadow"
            style={{ width: "100%", borderRadius: "21px" }}
          />
        </div>
      </div>
      <AboutCardContainer
        className="row  position-absolute  bottom-0  d-flex justify-content-end" 
      >
        
        <div className="col-1 col-md-1 col-lg-3 d-flex d-md-none"></div>
        {/* media query, give it 10% on small screens */}
        <div
          className="col-11 col-md-7 col-lg-5  p-5 p-md-5 shadow"
          style={{
            borderRadius: "21px",
            backgroundColor: "#FF3365",
            minHeight: "150px",
          }}
        >
          <AboutCardTitle>{title}</AboutCardTitle>
          <AboutCardBody>
            In amet dolor faucibus ullamcorper. Id massa quam aliquam ut
            phasellus. Feugiat at cursus dolor, augue sit. At quam dignissim
            feugiat a nunc id dignissim tempor. In pellentesque purus in
            malesuada.
          </AboutCardBody>
        </div>
        <div className="col-1 col-md-1 col-lg-3 d-none d-md-flex"></div>
      </AboutCardContainer>
    </div>
  );
}
