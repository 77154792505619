import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MoreVertIcon from '../../assets/icons/MoreVertIcon'
import RatingIcon from '../../assets/icons/RatingIcon'
import {
  TableContentGreyText,
  TableContentText,
  TableContentBoldText,
} from '../../assets/styles/styles'
import { useOutsideAlerter } from '../../helpers/useOutsideAlerter'
export default function ThemedTableBodyItem() {
  const [productDropdown, setproductDropdown] = useState(false)
  const wrapperRef = useRef(null)
  const navigate =useNavigate()
  useOutsideAlerter(wrapperRef, setproductDropdown)
  const handleToggle = () => {
    setproductDropdown(!productDropdown)
  }
  return (
    <div className="position-relative">
      <div
        className="row text-center align-items-center py-4 rounded-2 shadow-sm flex-nowrap"
        style={{
          // background: '#D0D0FB',
          fontSize: '1.4rem',
          fontWeight: '400',
          flex: 1,
        }}
      >
        <div className="col-3 col-md-2 text-break">
          {' '}
          <img
            src="/product1.png"
            alt=""
            width="100%"
            style={{
              width: '5.5rem',
              height: '5.5rem',
              objectFit: 'cover',
              borderRadius: '.4rem',
            }}
          />
        </div>
        <div className="col-8 col-md-2 text-break text-start text-md-center ">
          <TableContentBoldText className=" m-0">
            {' '}
            Non platea in mi dignissim vel pretium.
          </TableContentBoldText>
          <div className="d-block d-md-none">
            <TableContentText>N350.00</TableContentText>
          </div>
        </div>
        <div className="col-2 text-break d-none d-md-block">
          <TableContentText>28282C</TableContentText>
        </div>
        <div className="col-1 text-break d-none d-md-block">
          <TableContentText>N350.00</TableContentText>
        </div>
        <div className="col-1 text-break d-none d-md-block">
          <TableContentText>(400)</TableContentText>
        </div>
        <div className="col-1 text-break d-none d-md-block">
          <TableContentText>(175)</TableContentText>
        </div>
        <div className="col-2 text-break d-none d-md-block">
          <RatingIcon />
          <span>(60)</span>
        </div>
        <div
          className="col-1 "
          style={{ cursor: 'pointer' }}
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </div>
      </div>{' '}
      <div className="d-flex d-md-none justify-content-between mt-1 mb-2">
        <div className="">
          <TableContentGreyText>Quantity </TableContentGreyText>
          <TableContentText>(400)</TableContentText>
        </div>
        <div className="">
          <TableContentGreyText>Sold </TableContentGreyText>
          <TableContentText>(175)</TableContentText>
        </div>
        <div className="">
          <RatingIcon />
          <TableContentText>(60)</TableContentText>
        </div>
      </div>
      <div className="" ref={wrapperRef}>
        <div
          className={`${
            !productDropdown && 'd-none'
          } position-absolute bg-white  opacity-100 shadow-sm rounded `}
          style={{ top: '50%', right: 0,zIndex:10 }}
        >
          <ul className="list-unstyled m-0  px-2 text-start">
            <li
              className=" border-bottom px-3 py-1 "
              style={{ cursor: 'pointer' }}
              onClick={(e) =>{ e.stopPropagation();
                navigate("/products/1")}}
            >
              {' '}
              <TableContentText>View product</TableContentText>
            </li>
            <li
              className=" border-bottom px-3 py-1 "
              style={{ cursor: 'pointer' }} 
              onClick={(e) =>{ e.stopPropagation();
                navigate("/products/1")}}
            >
              {' '}
              <TableContentText>Edit product</TableContentText>
            </li>
            <li
              className=" border-bottom px-3 py-1"
              style={{ cursor: 'pointer' }} 
              onClick={(e) =>{ e.stopPropagation();
                navigate("/products/1")}}
            >
              {' '}
              <TableContentText>Remove product</TableContentText>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
