import React, { useMemo, useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { useUserAuth } from '../../../../context/UserAuthContext'
import { useUserStore } from '../../../../context/UserStoreContext'
import FormInputWithLabel from '../../../../components/FormInputWithLabel'
import ThemedFormButton from '../../../../components/ThemedFormButton'
import TextAreaWithLabel from '../../../../components/TextAreaWithLabel'
import FormFileInputWithLabel from '../../../../components/FormFileInputWithLabel'
import {
  ThemedRectangleInput,
  ThemedDivInput,
  ThemedDivNumberInput,
} from '../../../../assets/styles/styles'
import ProductSizes from '../../../../components/ProductSizes'
import PlusIcon from '../../../../assets/icons/PlusIcon'
import MinusIcon from '../../../../assets/icons/MinusIcon'
import RectangleBox from '../../../../assets/icons/RectangleBox'
import QuantityInput from '../../../../components/QuantityInput'

export default function CreateProduct() {
  const { stores } = useUserStore()
  const { user } = useUserAuth()
  const [isLoading, setisLoading] = useState(false)
  const navigate = useNavigate()
  const [error, setError] = useState('')

  return isLoading ? (
    <div>loading</div>
  ) : (
    <div className="row d-flex justify-content-start justify-content-md-center justify-content-lg-start  align-items-md-center">
      <div className="col col-md-6 col-lg-4">
        <FormInputWithLabel
          name="nameInput"
          placeholder="Enter product name here"
          type="text"
          label="Product name"
          // value={storeName}
          // setValue={setstoreName}
        />
        <FormInputWithLabel
          name="productImageInput"
          placeholder="Enter Product Price"
          type="text"
          label="Product Price"
          // value={storeName}
          // setValue={setstoreName}
        />
        <TextAreaWithLabel
          name="w3review"
          label="Product description "
          className="w-100"
          placeholder="Enter product description here"
          // value={welcomeText}
          // onChange={(e) => {
          //   setwelcomeText(e.target.value)
          // }}
        />
        <FormFileInputWithLabel
          name="nameInput"
          placeholder="Select image"
          type="file"
          label="Add product image (Min:4)"
          // value={storeName}
          // setValue={setstoreName}
        />
        <FormInputWithLabel
          name="nameInput"
          placeholder="Enter Product Price"
          type="text"
          label="Product ID"
          // value={storeName}
          // setValue={setstoreName}
        />
        <ProductSizes label="Select Available Sizes" />
      
        <FormInputWithLabel
          name="nameInput"
          placeholder="Enter Product Price"
          type="text"
          label="Product ID"
          // value={storeName}
          // setValue={setstoreName}
        />
      <QuantityInput label="Quantity"/>
        <ThemedFormButton placeholder="Save Changes" />
      </div>
    </div>
  )
}
