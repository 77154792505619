import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../../context/UserAuthContext";
import { useUserStore } from "../../../../context/UserStoreContext";
import { mapAuthCodeToMessage } from "../../../../helpers/errorHandlers";
import ThemedSearchBox from "../../../../components/ThemedSearchBox";
import Notifications from "../../../../assets/icons/Notifications";
import Settings from "../../../../assets/icons/Settings";
import {
  LargeCardHeader,
  LargeHeader,
  MediumParagraph,
  BoldCardSmallText,
  ListCardSmallText,
  ThemedTextarea,
  LargeDashboardHeader,
  MediumHeader,
  MediumDashboardHeader,
  StoreProductCard,
  TableContentBoldText,
  TableContentText,
} from "../../../../assets/styles/styles";
import ThemedTable from "../../../../components/ThemedTable/ThemedTable";
import Sidebar from "../../../../components/SideBar/SideBar";
import DashboardCard from "../../../../components/DashboardCard";
import EmptyStoreLargeIcon from "../../../../assets/icons/EmptyStoreLargeIcon";
import FormInputWithLabel from "../../../../components/FormInputWithLabel";
import ThemedFormButton from "../../../../components/ThemedFormButton";
import TextAreaWithLabel from "../../../../components/TextAreaWithLabel";
import ExampleLogo from "../../../../assets/icons/ExampleLogo";
import RatingIcon from "../../../../assets/icons/RatingIcon";

export default function CreateStore() {
  const { stores } = useUserStore();
  const { user } = useUserAuth();
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  return isLoading ? (
    <div>loading</div>
  ) : (
    <div className="container-fluid">
      <div className="row d-flex  ">
        <div className="col-12 col-md-5 col-lg-6 p-4" style={{maxHeight:"100vh", overflowY:"scroll"}}>
        
          <FormInputWithLabel
            name="nameInput"
            placeholder="Enter Store name here"
            type="text"
            label="Store Name"
            // value={storeName}
            // setValue={setstoreName}
          />
          <FormInputWithLabel
            name="nameInput"
            placeholder=".name.getlunnex.ng"
            type="text"
            label="Store link"
            // value={storeName}
            // setValue={setstoreName}
          />

          <TextAreaWithLabel
            name="w3review"
            label="Welcome text"
            className="w-100"
            placeholder="add a welcome text"
            // value={welcomeText}
            // onChange={(e) => {
            //   setwelcomeText(e.target.value)
            // }}
          />

          <FormInputWithLabel
            name="nameInput"
            placeholder="Select image"
            type="text"
            label="Store Logo"
            // value={storeName}
            // setValue={setstoreName}
          />

          <FormInputWithLabel
            name="nameInput"
            placeholder="Select image"
            type="text"
            label="Store Background image"
            // value={storeName}
            // setValue={setstoreName}
          />
          <FormInputWithLabel
            name="nameInput"
            placeholder="Add your Email address here"
            type="email"
            label="Email address "
            // value={storeName}
            // setValue={setstoreName}
          />

          <ThemedFormButton placeholder="Save Changes" />
        </div> 
        <div className="col"></div>
        <div
          className="col-12 col-md-5 col-lg-5 rounded m-2 px-5"
          style={{ minHeight: "100%", maxHeight:"100vh", overflow:"auto", border: ".1rem solid #000000" }}
        >
          <div className="d-flex justify-content-between gap-2 align-items-center sticky-top bg-white py-5">
            <ExampleLogo />{" "}
            <ThemedSearchBox right placeholder="Search for products..." />
          </div>
          <div
            className="mt-2 position-relative"
            style={{
              height: "30rem",
              width: "100%",
              overflow: "hidden",
              borderRadius: ".4rem",
            }}
          >
            <img src="/product1large.png" alt="" width="100%" height="100%" />

            <div
              className="position-absolute w-100 h-100 "
              style={{
                // zIndex: 10,
                backgroundColor: "#000000",
                top: "0",
                opacity: "0.7",
                borderRadius: ".4rem",
              }}
            ></div>

            <p
              className=" position-absolute text-white text-center d-block align-self-center"
              style={{
                opacity: 10,
                top: 0,
                bottom: 0,
                margin: "auto",
                height: "fit-content",
              }}
            >
              <LargeCardHeader>
                Collection of Local Africa’s Fabrics
              </LargeCardHeader>
            </p>
          </div>

          <div className="text-black text-center my-3">
            <LargeDashboardHeader className="mb-2  ">
              LONI’s LATEST PRODUCT
            </LargeDashboardHeader>

            <MediumDashboardHeader>
              Trending product this week
            </MediumDashboardHeader>
          </div>

          <div className="row ">
            <div className="col-12 col-md-6">
              <StoreProductCard className="card mb-2">
                <div className="">
                  <img src="/product1large.png" alt="" width="100%" />
                </div>

                <div className="p-2">
                  <div className="">
                    <TableContentBoldText className="m-0">
                      ZenithAura™
                    </TableContentBoldText>
                    <TableContentBoldText className="m-0">
                      LuminescentWhispe
                    </TableContentBoldText>
                  </div>

                  <div className="d-flex justify-content-between mt-2">
                    <TableContentText>N250</TableContentText>

                    <RatingIcon />
                  </div>
                  <div className="p-2">
                    <ThemedFormButton placeholder="Add to cart" />
                  </div>
                </div>
              </StoreProductCard>
            </div>
            <div className="col-12 col-md-6">
              <StoreProductCard className="card mb-2">
                <div className="">
                  <img src="/product1large.png" alt="" width="100%" />
                </div>

                <div className="p-2">
                  <div className="">
                    <TableContentBoldText className="m-0">
                      ZenithAura™
                    </TableContentBoldText>
                    <TableContentBoldText className="m-0">
                      LuminescentWhispe
                    </TableContentBoldText>
                  </div>

                  <div className="d-flex justify-content-between mt-2">
                    <TableContentText>N250</TableContentText>

                    <RatingIcon />
                  </div>
                  <div className="p-2">
                    <ThemedFormButton placeholder="Add to cart" />
                  </div>
                </div>
              </StoreProductCard>
            </div>
            <div className="col-12 col-md-6">
              <StoreProductCard className="card mb-2">
                <div className="">
                  <img src="/product1large.png" alt="" width="100%" />
                </div>

                <div className="p-2">
                  <div className="">
                    <TableContentBoldText className="m-0">
                      ZenithAura™
                    </TableContentBoldText>
                    <TableContentBoldText className="m-0">
                      LuminescentWhispe
                    </TableContentBoldText>
                  </div>

                  <div className="d-flex justify-content-between mt-2">
                    <TableContentText>N250</TableContentText>

                    <RatingIcon />
                  </div>
                  <div className="p-2">
                    <ThemedFormButton placeholder="Add to cart" />
                  </div>
                </div>
              </StoreProductCard>
            </div>
            <div className="col-12 col-md-6">
              <StoreProductCard className="card mb-2">
                <div className="">
                  <img src="/product1large.png" alt="" width="100%" />
                </div>

                <div className="p-2">
                  <div className="">
                    <TableContentBoldText className="m-0">
                      ZenithAura™
                    </TableContentBoldText>
                    <TableContentBoldText className="m-0">
                      LuminescentWhispe
                    </TableContentBoldText>
                  </div>

                  <div className="d-flex justify-content-between mt-2">
                    <TableContentText>N250</TableContentText>

                    <RatingIcon />
                  </div>
                  <div className="p-2">
                    <ThemedFormButton placeholder="Add to cart" />
                  </div>
                </div>
              </StoreProductCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
