import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../../../assets/icons/Logo'
import {
  AuthPageWrapper,
  LargeHeader,
  MediumHeader,
  ThemedButton,
} from '../../../../assets/styles/styles'
import SelectInput from '../../../../components/SelectInput'
import ThemedFormButton from '../../../../components/ThemedFormButton'
import { useStoreProductCategory } from '../../../../context/UserProductCategoryContext'
import { useUserStore } from '../../../../context/UserStoreContext'
import { mapThemeCodeToMessage } from '../../../../helpers/errorHandlers'

export default function SelectProduct() {
  const navigate = useNavigate()
  const { productCategories } = useStoreProductCategory()
  const [selectedProductCategory, setselectedProductCategory] = useState(null)
  const { addDataToSingleStore, currentStore, stores } = useUserStore()
  const [error, setError] = useState('')
  const [isLoading, setisLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    try {
      setisLoading(true)
      if (!selectedProductCategory) throw new Error('no product selected')

      addDataToSingleStore(currentStore, {
        product: selectedProductCategory.name,
      })
      setisLoading(false)
      navigate('/add-welcome-text')
    } catch (err) {
      setError(mapThemeCodeToMessage(err.message))
      setisLoading(false)
    }
  }

  return (
    <>
      <AuthPageWrapper className="row justify-content-center pt-5">
        <div
          className="col-11 col-md-5 col-lg-4 "
          style={{ height: 'fit-content' }}
        >
          <div className="text-center flex-wrap">
            <Logo />

            <MediumHeader className="mt-5 mb-5">Welcome 👋</MediumHeader>
            <LargeHeader className="pt-5 mb-4">
              What type of products do you sell?
            </LargeHeader>
          </div>
          <form onSubmit={handleSubmit}>
            {isLoading && <div>loading</div>}
            {error && (
              <div className="border border-danger rounded text-danger px-2 py-1 font-monospace text-center">
                {error}
              </div>
            )}
            <SelectInput
              data={productCategories}
              placeholder="Select a category?"
              value={selectedProductCategory}
              setValue={setselectedProductCategory}
            />

            <ThemedFormButton isLoading={isLoading} placeholder="Continue" />
          </form>
        </div>
      </AuthPageWrapper>
    </>
  )
}
