const storeThemes = [
  { id: 1, name: "black", color: "#000000" },
  { id: 2, name: "red", color: "#FF3365" },
];
let storeProductCategories = [
  { id: 1, name: "Lifestyle" },
  { id: 2, name: "Fashion" },
  { id: 3, name: "Technology" },
  { id: 4, name: "Entertainment" },
];

module.exports = { storeThemes, storeProductCategories };
