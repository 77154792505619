import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../../context/UserAuthContext";
import { useUserStore } from "../../../../context/UserStoreContext";
import { mapAuthCodeToMessage } from "../../../../helpers/errorHandlers";
import ThemedSearchBox from "../../../../components/ThemedSearchBox";
import Notifications from "../../../../assets/icons/Notifications";
import Settings from "../../../../assets/icons/Settings";
import {
  LargeCardHeader,
  LargeHeader,
  MediumParagraph,
  BoldCardSmallText,
  ListCardSmallText,
  LargeDashboardHeader,
  MediumDashboardHeader,
} from "../../../../assets/styles/styles";
import ThemedTable from "../../../../components/ThemedTable/ThemedTable";
import Sidebar from "../../../../components/SideBar/SideBar";
import DashboardCard from "../../../../components/DashboardCard";
import AvatarWrapper from "../../../../components/AvatarWrapper";

export default function Profile() {
  const { stores } = useUserStore();
  const { user } = useUserAuth();
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  // Using useEffect to call the API once mounted and set the data

  return isLoading ? (
    <div>loading</div>
  ) : (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-center flex-column gap-4 mt-5">
        <AvatarWrapper size="15" />
        <LargeCardHeader>Bettie Hampton</LargeCardHeader>
        <MediumDashboardHeader>Edit profile</MediumDashboardHeader>
      </div>
      <div className=" mt-4 p-5">
        <ul className="list-unstyled">
          <li className="mb-4">
            {" "}
            <MediumDashboardHeader><Link>Edit profile</Link></MediumDashboardHeader>
          </li>
          <li className="mb-4">
            {" "}
            <MediumDashboardHeader><Link>Account Setting</Link></MediumDashboardHeader>
          </li>
          <li className="mb-4">
            {" "}
            <MediumDashboardHeader><Link>Change Password</Link></MediumDashboardHeader>
          </li>
        </ul>
      </div>
    </div>
  );
}
