import React from 'react'
import {
  ProductSizeWrapper,
  ProductSizeText,
  ThemedLabel,
} from '../../assets/styles/styles'

export default function ProductSizes(props) {
  const { label } = props
  return (
    <div className="">
      {label && <ThemedLabel className="">{label}</ThemedLabel>}
 
      <ProductSizeWrapper className="my-3">
        <ProductSizeText>S</ProductSizeText>
        <ProductSizeText>M</ProductSizeText>
        <ProductSizeText className="active">L</ProductSizeText>
        <ProductSizeText>XL</ProductSizeText>
      </ProductSizeWrapper>
    </div>
  )
}
