import React from 'react';
import { BoldCardSmallText, LargeCardHeader, ListCardSmallText, MediumParagraph } from '../../assets/styles/styles';

export default function DashboardCard() {
    
  const cardStyle = {
    borderRadius: '1.5rem',
    boxShadow: '0rem 1.3rem 3.7rem 0rem rgba(0, 0, 0, 0.08)',
    borderBottom: ' 1.5rem solid #FFBDCD',
  }
  return (
  
    <div className="p-4 col" style={cardStyle}>
    <MediumParagraph className="pb-0 m-0">
      Today’s Orders
    </MediumParagraph>

    <LargeCardHeader className="mt-1 mb-2 align-content-start text-start">
      N200,000
    </LargeCardHeader>
    <div className=" row">
      <div className="col">
        <BoldCardSmallText> PRODUCTS </BoldCardSmallText>{' '}
        <ListCardSmallText> (400) </ListCardSmallText>
        <BoldCardSmallText> SOLD </BoldCardSmallText>{' '}
        <ListCardSmallText> (400) </ListCardSmallText>
        <BoldCardSmallText>REMAINING</BoldCardSmallText>{' '}
        <ListCardSmallText>(400)</ListCardSmallText>
      </div>
      <div className="col-5 col-md-4 col-lg-3 text-end">
        <ListCardSmallText>24 - 06 -2022</ListCardSmallText>
      </div>
    </div>
  </div>

  );
}
