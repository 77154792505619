import React, { useMemo, useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { useUserAuth } from '../../../../context/UserAuthContext'
import { useUserStore } from '../../../../context/UserStoreContext'
import EmptyStoreLargeIcon from '../../../../assets/icons/EmptyStoreLargeIcon'
import ThemedTable from '../../../../components/ThemedTable/ThemedTable'

export default function Products() {
  const { stores } = useUserStore()
  const { user } = useUserAuth()
  const [isLoading, setisLoading] = useState(false)
  const navigate = useNavigate()
  const [error, setError] = useState('')

  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([])

  return isLoading ? <div>loading</div> : <ThemedTable />
}
