import React from "react";

function ProductIcon(props) {
  const { active, mobile } = props; 
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={active & mobile ? "#FF3365" : active ? "none" : "black"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.4206 0.910169C8.86427 0.678502 9.39251 0.675339 9.83892 0.901677L14.2315 3.12875C15.3636 3.70273 15.3636 5.31965 14.2315 5.89363L9.83892 8.1207C9.39251 8.34704 8.86427 8.34388 8.4206 8.11221L4.15546 5.88514C3.04538 5.3055 3.04538 3.71688 4.15546 3.13724L8.4206 0.910169Z"
        fill={active && mobile ? "#FF3365" : active ? "white" : "black"}
      />
      <path
        d="M0.789062 8.08789C0.789062 6.94019 1.99297 6.19056 3.02291 6.69694L7.51262 8.90438C8.04283 9.16507 8.37872 9.7045 8.37872 10.2953V15.4109C8.37872 16.5417 7.20689 17.2919 6.17991 16.8185L1.6902 14.749C1.1409 14.4958 0.789062 13.9462 0.789062 13.3414V8.08789Z"
        fill={active && mobile ? "#FF3365" : active ? "white" : "black"}
      />
      <path
        d="M17.4014 8.08789C17.4014 6.94019 16.1975 6.19056 15.1675 6.69694L10.6778 8.90438C10.1476 9.16507 9.81171 9.7045 9.81171 10.2953V15.4109C9.81171 16.5417 10.9835 17.2919 12.0105 16.8185L16.5002 14.749C17.0495 14.4958 17.4014 13.9462 17.4014 13.3414V8.08789Z"
        fill={active && mobile ? "#FF3365" : active ? "white" : "black"}
      />
    </svg>
  );
}

export default ProductIcon;
