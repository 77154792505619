import React from 'react'

function OrderIcon(props) {
  const { active,mobile } = props;
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill={active && mobile ? "#FF3365" : active ? "none" : "black"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0947 11.1865C14.5447 11.1865 14.0737 10.9905 13.6817 10.5985C13.2897 10.2065 13.0941 9.73586 13.0947 9.18653V5.18653C13.0947 4.63653 13.2907 4.16552 13.6827 3.77352C14.0747 3.38153 14.5454 3.18586 15.0947 3.18653H19.0947C19.6447 3.18653 20.1157 3.38253 20.5077 3.77453C20.8997 4.16653 21.0954 4.63719 21.0947 5.18653V9.18653C21.0947 9.73653 20.8987 10.2075 20.5067 10.5995C20.1147 10.9915 19.6441 11.1872 19.0947 11.1865H15.0947ZM11.0947 9.18653C11.0947 9.73653 10.8987 10.2075 10.5067 10.5995C10.1147 10.9915 9.64406 11.1872 9.09473 11.1865H5.09473C4.54473 11.1865 4.07373 10.9905 3.68173 10.5985C3.28973 10.2065 3.09406 9.73586 3.09473 9.18653V5.18653C3.09473 4.63653 3.29073 4.16552 3.68273 3.77352C4.07473 3.38153 4.54539 3.18586 5.09473 3.18653H9.09473C9.64473 3.18653 10.1157 3.38253 10.5077 3.77453C10.8997 4.16653 11.0954 4.63719 11.0947 5.18653V9.18653ZM13.0947 15.1865C13.0947 14.6365 13.2907 14.1655 13.6827 13.7735C14.0747 13.3815 14.5454 13.1859 15.0947 13.1865H19.0947C19.6447 13.1865 20.1157 13.3825 20.5077 13.7745C20.8997 14.1665 21.0954 14.6372 21.0947 15.1865V19.1865C21.0947 19.7365 20.8987 20.2075 20.5067 20.5995C20.1147 20.9915 19.6441 21.1872 19.0947 21.1865H15.0947C14.5447 21.1865 14.0737 20.9905 13.6817 20.5985C13.2897 20.2065 13.0941 19.7359 13.0947 19.1865V15.1865ZM9.09473 13.1865C9.64473 13.1865 10.1157 13.3825 10.5077 13.7745C10.8997 14.1665 11.0954 14.6372 11.0947 15.1865V19.1865C11.0947 19.7365 10.8987 20.2075 10.5067 20.5995C10.1147 20.9915 9.64406 21.1872 9.09473 21.1865H5.09473C4.54473 21.1865 4.07373 20.9905 3.68173 20.5985C3.28973 20.2065 3.09406 19.7359 3.09473 19.1865V15.1865C3.09473 14.6365 3.29073 14.1655 3.68273 13.7735C4.07473 13.3815 4.54539 13.1859 5.09473 13.1865H9.09473Z"
        fill={active && mobile ? "#FF3365" : active ? "white" : "black"}
      />
    </svg>
  )
}

export default OrderIcon
