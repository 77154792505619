import React, { useRef, useState } from 'react'
import SelectDropdown from '../../assets/icons/SelectDropdown'
import {
  ThemedInput,
  ThemedInputDiv,
  ThemedLabel,
} from '../../assets/styles/styles'
import { useOutsideAlerter } from '../../helpers/useOutsideAlerter';
import './selectInput.css'
export default function SelectInput(props) {
  const wrapperRef = useRef(null);
  const [isDropdownActive, setisDropdownActive] = useState(false)
  
  const {  placeholder,  data,value, setValue } = props 
  useOutsideAlerter(wrapperRef,setisDropdownActive) 

const handleChange = (value)=>{
  setValue(value) 
  setisDropdownActive(false)
}

  return (
    <div className="position-relative" ref={wrapperRef}>
      <ThemedInputDiv
        className="d-flex px-2 px-md-3 gap-2 align-items-center position-relative"
        onClick={() => setisDropdownActive(!isDropdownActive)}
        
      >
        {/* <input
          type="text"
          placeholder="Select a category?"
          className="border-0 w-100"
          value=""
          disabled
        />{' '} */}

        <div
          className="border-0 w-100" style={{cursor:"pointer"}}>
        {value?.name||placeholder }

        
        </div>
        <SelectDropdown />
      </ThemedInputDiv>
      <div
        className={`w-100 shadow rounded-2 position-absolute ${
          !isDropdownActive ? 'd-none' : ''
        }`}
        style={{ zIndex: 100, backgroundColor: '#FFF' }}

      >
        <ul className=" list-unstyled py-1 px-3 themed-select-input">
          {(data||[]).map((product,index)=>{
          return (<li className=" border-bottom p-2" key={index} onClick={()=>handleChange(product)}>{product.name || ""}</li>)
          })} </ul>
      </div>
    </div>
  )
}
