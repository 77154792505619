import React from "react";
import {
  InfoCardBody,
  InfoCardCircle,
  InfoCardNumber,
  InfoCardTitle,
  InfoCardWrapper,
} from "../../assets/styles/styles";

export default function InfoCard(props) {
  const {id, title, details}=props
  return (
    <div className="col-12 col-md-6 col-lg-4 py-4">
    <InfoCardWrapper className="p-2 px-4 shadow">
      <InfoCardCircle></InfoCardCircle>
      <InfoCardNumber>{id}</InfoCardNumber>
      <InfoCardTitle>{title}</InfoCardTitle>
      <InfoCardBody className="mt-4">{details}</InfoCardBody>
    </InfoCardWrapper>
     </div>
  );
}
