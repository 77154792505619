import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../../assets/icons/Logo'
import {
  MediumHeader,
  MediumParagraph,
  ThemedButton,
  AuthPageWrapper,
} from '../../../assets/styles/styles'
import { useUserAuth } from '../../../context/UserAuthContext'
import FormInputWithLabel from '../../../components/FormInputWithLabel'
import ThemedFormButton from '../../../components/ThemedFormButton'
import { mapAuthCodeToMessage } from '../../../helpers/errorHandlers'

export default function SignUp() {
  const { signUpWithName, signUp } = useUserAuth()
  const [name, setname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setisLoading] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    try { 
      setisLoading(true)
       await signUpWithName(email, password,name)
      setisLoading(false)
 
      navigate('/store-name')
    } catch (err) { 
      setError(mapAuthCodeToMessage(err.code)) 
      console.error(err.message)
      setisLoading(false)  
    }
  }

  const navigate = useNavigate()
  return (
    <>
      <AuthPageWrapper className="container-fluid row justify-content-center pt-5">
        <div
          className="col-11 col-md-5 col-lg-4 "
          style={{ height: 'fit-content' }}
        >
          <div className="text-center">
            <Logo  />

            <MediumHeader className="mt-5 mb-3">Welcome 👋</MediumHeader>
            <MediumParagraph>
              Enter your details to create an account.
            </MediumParagraph>
          </div>

          <form onSubmit={handleSubmit}>
            {isLoading && <div>loading</div>}
          { error && <div className="border border-danger rounded text-danger px-2 py-1 font-monospace text-center">{error}</div>}
            <FormInputWithLabel
              name="nameInput"
              placeholder="Your name"
              type="text"
              label="Name"
              value={name}
              setValue={setname}
            />
            <FormInputWithLabel
              name="emailInput"
              placeholder="Your email"
              type="email"
              label="Email"
              value={email}
              setValue={setEmail}
            />
            <FormInputWithLabel
              name="passwordInput"
              placeholder="Create a password"
              type="password"
              label="Password"
              value={password}
              setValue={setPassword}
            />
 
            
            <ThemedFormButton isLoading={isLoading} placeholder="Create Account"/>
          </form>
          <MediumParagraph className="text-center mt-4">
            By creating an account, you agree to our{' '}
            <strong>
              <Link to=""> Terms</Link>
            </strong>
            .<br />
            Already have an account?{' '}
            <strong>
              <Link to="/login">Log In</Link>
            </strong>
          </MediumParagraph>
        </div>
      </AuthPageWrapper>
    </>
  )
}
