import React from 'react'

function UploadImageIcon() {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.44336 16.4857H11.4434C12.1309 16.4857 12.6934 15.9232 12.6934 15.2357V8.98574H14.6809C15.7934 8.98574 16.3559 7.63574 15.5684 6.84824L9.83086 1.11074C9.34336 0.623242 8.55586 0.623242 8.06836 1.11074L2.33086 6.84824C1.54336 7.63574 2.09336 8.98574 3.20586 8.98574H5.19336V15.2357C5.19336 15.9232 5.75586 16.4857 6.44336 16.4857ZM1.44336 18.9857H16.4434C17.1309 18.9857 17.6934 19.5482 17.6934 20.2357C17.6934 20.9232 17.1309 21.4857 16.4434 21.4857H1.44336C0.755859 21.4857 0.193359 20.9232 0.193359 20.2357C0.193359 19.5482 0.755859 18.9857 1.44336 18.9857Z"
        fill="#A0A0A0"
      />
    </svg>
  )
}

export default UploadImageIcon
