import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../../../assets/icons/Logo'
import {
  AuthPageWrapper,
  LargeHeader,
  MediumHeader,
  ThemeCardWrapper,
  ThemedButton,
} from '../../../../assets/styles/styles'
import ThemedFormButton from '../../../../components/ThemedFormButton'
import { useUserStore } from '../../../../context/UserStoreContext'
import { useUserTheme } from '../../../../context/UserThemeContext'
import { 
  mapThemeCodeToMessage,
} from '../../../../helpers/errorHandlers'
export default function SelectTheme() {
  const navigate = useNavigate()
  const { themes } = useUserTheme()
  const { addDataToSingleStore, currentStore } = useUserStore()
  const [error, setError] = useState('')
  const [isLoading, setisLoading] = useState(false)
  const [selectedTheme, setselectedTheme] = useState(null) 
  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    try {
      setisLoading(true)
      if (!selectedTheme) throw new Error('no theme selected')

      addDataToSingleStore(currentStore, { theme: selectedTheme.name })
      setisLoading(false)
      navigate('/select-product')
    } catch (err) {
      setError(mapThemeCodeToMessage(err.message))
      setisLoading(false)
    }
  }
  return (
    <>
      <AuthPageWrapper className="row justify-content-center pt-5">
        <div
          className="col-11 col-md-5 col-lg-4 "
          style={{ height: 'fit-content' }}
        >
          <div className="text-center flex-wrap">
            <Logo />

            <MediumHeader className="mt-5 mb-5">Welcome 👋</MediumHeader>
            <LargeHeader className="pt-5 mb-4">
              Select a theme for your store
            </LargeHeader>
          </div>
          <form onSubmit={handleSubmit}>
            {isLoading && <div>loading</div>}
            {error && (
              <div className="border border-danger rounded text-danger px-2 py-1 font-monospace text-center">
                {error}
              </div>
            )} 
            <ThemeCardWrapper className="d-flex px-1 py-2 px-md-3 py-md-3 gap-2">
              {themes.map((theme, index) => {
                return (
                  <div className="ratio ratio-4x3" key={index}>
                    <div
                      className={` ${
                        selectedTheme && selectedTheme.id == theme.id
                          ? ''
                          : 'border'
                      }  rounded-4 p-2`}
                      key={index}
                      onClick={() => setselectedTheme(theme)}
                      style={
                        selectedTheme && selectedTheme.id == theme.id
                          ? {
                              transform: 'scale(1.03)',
                              border: `.12rem solid ${theme.color}`,
                            }
                          : {}
                      }
                    >
                      <div
                        className="border rounded-4 h-100  "
                        style={{
                          backgroundColor: theme.color,
                          cursor: 'pointer',
                        }}
                      ></div>
                    </div>
                  </div>
                )
              })}
            </ThemeCardWrapper>

            <ThemedFormButton isLoading={isLoading} placeholder="Continue" />
          </form>
        </div>
      </AuthPageWrapper>
    </>
  )
}
