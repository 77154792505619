import React, { useMemo, useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { useUserAuth } from '../../../../context/UserAuthContext'
import { useUserStore } from '../../../../context/UserStoreContext'
import { mapAuthCodeToMessage } from '../../../../helpers/errorHandlers'
import ThemedSearchBox from '../../../../components/ThemedSearchBox'
import Notifications from '../../../../assets/icons/Notifications'
import Settings from '../../../../assets/icons/Settings'
import {
  LargeCardHeader,
  LargeHeader,
  MediumParagraph,
  BoldCardSmallText,
  ListCardSmallText,
} from '../../../../assets/styles/styles'
import ThemedTable from '../../../../components/ThemedTable/ThemedTable'
import Sidebar from '../../../../components/SideBar/SideBar'
import DashboardCard from '../../../../components/DashboardCard'
import EmptyStoreLargeIcon from '../../../../assets/icons/EmptyStoreLargeIcon'
import NavBar from '../../../../components/NavBar/NavBar'

export default function Store() {
  const { stores } = useUserStore()
  const { user } = useUserAuth()
  const [isLoading, setisLoading] = useState(false)
  const navigate = useNavigate()
  const [error, setError] = useState('')

  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([])

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    ;(async () => {
      const result = await axios('https://api.tvmaze.com/search/shows?q=snow')
      setData(result.data)
    })()
  }, [])
 
  return isLoading ? (
    <div>loading</div>
  ) : (
    <div
      className="d-flex align-items-center flex-column p-5"
      style={{
        backgroundColor: '#F5F5F5',
        height:"100%",
        maxHeight: '100vh',
        borderRadius: '2.5rem',
      }}
    >
      <EmptyStoreLargeIcon />

      <div
        className=" p-5 text-center"
        style={{ backgroundColor: '#FF3365', borderRadius: '.8rem' }}
      >
        <p className="text-white">You don’t have a Store yet</p>

        <button className="border-0 bg-white rounded-2 px-4 py-2" onClick={()=>navigate("/store/create")}>
          Create Store
        </button>
      </div>
    </div>
  )
}
