import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../../../assets/icons/Logo'
import {
  AuthPageWrapper,
  LargeHeader,
  MediumHeader,
  ThemedButton,
  ThemedCancelButton,
  ThemedTextarea,
} from '../../../../assets/styles/styles'
import TextAreaWithLabel from '../../../../components/TextAreaWithLabel'
import ThemedFormButton from '../../../../components/ThemedFormButton'
import { useUserStore } from '../../../../context/UserStoreContext'
import { mapThemeCodeToMessage } from '../../../../helpers/errorHandlers'

export default function AddWelcomeText() {
  const [welcomeText, setwelcomeText] = useState('')
  const navigate = useNavigate()
  const { addDataToSingleStore, currentStore, stores } = useUserStore()
  const [error, setError] = useState('')
  const [isLoading, setisLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    try {
      setisLoading(true)
      if (!welcomeText || welcomeText == '')
        throw new Error('Empty welcome text')

      addDataToSingleStore(currentStore, {
        welcomeText: welcomeText,
      })
      setisLoading(false)
      navigate('/')
    } catch (err) {
      setError(mapThemeCodeToMessage(err.message))
      setisLoading(false)
    }
  }
  return (
    <>
      <AuthPageWrapper className="row justify-content-center pt-5">
        <div
          className="col-11 col-md-5 col-lg-4 "
          style={{ height: 'fit-content' }}
        >
          <div className="text-center flex-wrap">
            <Logo />

            <MediumHeader className="mt-5 mb-5">Welcome 👋</MediumHeader>
            <LargeHeader className="pt-5 mb-4">
              Add a welcome text for your customers
            </LargeHeader>
          </div>
          <form onSubmit={handleSubmit}>
            {isLoading && <div>loading</div>}
            {error && (
              <div className="border border-danger rounded text-danger px-2 py-1 font-monospace ">
                {error}
              </div>
            )}
            <TextAreaWithLabel
              name="w3review" 
              placeholder="add a welcome text"
              value={welcomeText}
              onChange={(e) => {
                setwelcomeText(e.target.value)
              }}
            />

            <ThemedFormButton isLoading={isLoading} placeholder="Continue" />
          </form>
          <ThemedCancelButton
            onClick={(e) => {
              e.preventDefault()
              navigate('/')
            }}
          >
            Skip now, Add later
          </ThemedCancelButton>
        </div>
      </AuthPageWrapper>
    </>
  )
}
