import React from 'react'
import { ThemedInput, ThemedLabel } from '../../assets/styles/styles'

export default function RememberMe(props) {
  return (
    <div className="mb-3">
      <input type="checkbox" name="" id="" />{' '}
      <ThemedLabel htmlFor={`rememberme`} className="">
        Remember Me
      </ThemedLabel>
    </div>
  )
}
