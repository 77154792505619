import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../../context/UserAuthContext";
import { useUserStore } from "../../../../context/UserStoreContext";
import EmptyStoreLargeIcon from "../../../../assets/icons/EmptyStoreLargeIcon";
import NavBar from "../../../../components/NavBar/NavBar";
import {
  LargeDashboardHeader,
  MediumWalletHeader,
  SmallDashboardHeader,
  TableContentText,
  ThemeCalendarItem,
  ThemeCalendarWrapper,
  ThemedWalletButton,
  ThemedWalletTaskButton,
  WalletListButtonText,
  WalletListText,
} from "../../../../assets/styles/styles";
import AvatarWrapper from "../../../../components/AvatarWrapper";
import WalledCardListItem from "../../../../components/WalledCardListItem/ThemedTableBodyItem";
import WallectActivitiesChart from "../../../../components/charts/WallectActivitiesChart";

export default function Wallet() {
  const { stores } = useUserStore();
  const { user } = useUserAuth();
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]);

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    (async () => {
      const result = await axios("https://api.tvmaze.com/search/shows?q=snow");
      setData(result.data);
    })();
  }, []);
  const days = new Array(30).fill(0);
  const rows = ["S", "M", "T", "W", "T", "F", "S"];
  return isLoading ? (
    <div>loading</div>
  ) : (
    <div className="container-fluid">
      <div className="row mb-5 py-2">
        <div className="col-12 col-md-8 mb-3">
          <div className="shadow p-4 rounded ">
            <div className="d-flex justify-content-between mb-3">
              <div className="d-flex gap-3">
                <AvatarWrapper />
                <div className="">
                  <SmallDashboardHeader className="m-0">
                    Vendor
                  </SmallDashboardHeader>

                  <MediumWalletHeader className="mb-1">
                    Egbon Adugbo
                  </MediumWalletHeader>
                  <SmallDashboardHeader className="m-0">
                    Vendor
                  </SmallDashboardHeader>
                </div>
              </div>
              <div className="">
                <LargeDashboardHeader>N33,500</LargeDashboardHeader>
              </div>
            </div>

            <div className="d-block d-md-flex gap-3 mb-3 ">
              <ThemedWalletButton>Withdraw</ThemedWalletButton>
              <ThemedWalletButton>Deposit</ThemedWalletButton>
              <ThemedWalletButton>Transfer</ThemedWalletButton>
            </div>
            <div className="">
              <ThemedWalletTaskButton>Daily Task</ThemedWalletTaskButton>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 mb-3">
          <div className="shadow rounded-3 overflow-hidden h-100">
            <div className="p-3 " style={{ backgroundColor: "#26264C" }}>
              <span className="text-white">Daily tasks</span>
            </div>
            <div className="p-2 h-100">
              <ThemeCalendarWrapper>
                {rows.map((item, index) => {
                  return (
                    <ThemeCalendarItem className="" key={index}>
                     <TableContentText> {item}</TableContentText>
                    </ThemeCalendarItem>
                  );
                })}
                {days.map((item, index) => {
                  return (
                    <ThemeCalendarItem className="" key={index}>
                    <TableContentText>  {item}</TableContentText>
                    </ThemeCalendarItem>
                  );
                })}
              </ThemeCalendarWrapper>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-4 mb-5">
          <div className="shadow rounded p-4">
            <div className="mb-4">
              <span>Transactions</span>
            </div>
            <div
              className="d-flex gap-2 align-items-center justify-content-between mb-2 px-4"
              style={{ overflowX: "auto" }}
            >
              <AvatarWrapper name="Jade" size="4" />
              <AvatarWrapper name="Jade" size="4" />
              <AvatarWrapper name="Jade" size="4" />
              <AvatarWrapper name="Jade" size="4" /> 
            </div>

            <div className="">
              <ul className="m-0 p-0">
             <WalledCardListItem/>
             <WalledCardListItem/>
             <WalledCardListItem/>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8">
        <div className="shadow rounded overflow-hidden h-100">
            <div className="p-2 " style={{ backgroundColor: "#26264C" }}>
              <span className="text-white">Daily tasks</span>
            </div>
            <div className="p-2 ">
          <WallectActivitiesChart/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
