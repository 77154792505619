import React from 'react'

function RatingIcon() {
  return (
    <svg
      width="63"
      height="13"
      viewBox="0 0 63 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.94395 0.714844L8.67331 4.46409L12.7735 4.95022L9.74212 7.75352L10.5468 11.8032L6.94395 9.78651L3.34112 11.8032L4.14579 7.75352L1.11445 4.95022L5.2146 4.46409L6.94395 0.714844Z"
        fill="#FF9A3E"
      />
      <path
        d="M19.2027 0.714844L20.9321 4.46409L25.0322 4.95022L22.0009 7.75352L22.8056 11.8032L19.2027 9.78651L15.5999 11.8032L16.4046 7.75352L13.3732 4.95022L17.4734 4.46409L19.2027 0.714844Z"
        fill="#FF9A3E"
      />
      <path
        d="M31.462 0.714844L33.1914 4.46409L37.2915 4.95022L34.2602 7.75352L35.0649 11.8032L31.462 9.78651L27.8592 11.8032L28.6639 7.75352L25.6325 4.95022L29.7327 4.46409L31.462 0.714844Z"
        fill="#FF9A3E"
      />
      <path
        d="M43.7208 0.714844L45.4502 4.46409L49.5503 4.95022L46.519 7.75352L47.3236 11.8032L43.7208 9.78651L40.118 11.8032L40.9226 7.75352L37.8913 4.95022L41.9915 4.46409L43.7208 0.714844Z"
        fill="#FF9A3E"
      />
      <path
        d="M55.9801 0.714844L57.7094 4.46409L61.8096 4.95022L58.7782 7.75352L59.5829 11.8032L55.9801 9.78651L52.3773 11.8032L53.1819 7.75352L50.1506 4.95022L54.2507 4.46409L55.9801 0.714844Z"
        fill="#EFEFEF"
      />
    </svg>
  )
}

export default RatingIcon
