import React, { useState } from 'react'
import DottedElipseLoader from '../../assets/DottedElipseLoader'
import SelectDropdown from '../../assets/icons/SelectDropdown'
import { ThemedButton,ThemedEditButton } from '../../assets/styles/styles'

export default function ThemedFormRemoveButton(props) {
  const { isLoading, loadingText = 'loading', placeholder = '' } = props
  return (
    <ThemedEditButton
      className={isLoading ? 'loading' : ''}
      disabled={isLoading}
      className="d-flex justify-content-center align-items-center gap-2 mb-3"
    >
      <span> {isLoading ? loadingText : placeholder}</span>{' '}
      {isLoading && <DottedElipseLoader />}
    </ThemedEditButton>
  )
}



 
