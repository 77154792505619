import React from 'react'
import MinusIcon from '../../assets/icons/MinusIcon'
import PlusIcon from '../../assets/icons/PlusIcon'
import { ThemedLabel, ThemedDivNumberInput } from '../../assets/styles/styles'

export default function QuantityInput(props) {
  const { label } = props
  return (
    <div className="mb-3">
      {label && <ThemedLabel className="">{label}</ThemedLabel>}

      <div className="d-flex align-items-center   gap-1 my-1">
        <PlusIcon />{' '}
        <ThemedDivNumberInput className=" px-2">s</ThemedDivNumberInput>
        <MinusIcon />
      </div>
    </div>
  )
}
