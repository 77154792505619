import React from 'react'
import { ThemedInput, ThemedLabel } from '../../assets/styles/styles'

export default function FormInputWithLabel(props) {
  const { name, placeholder, label, type, value, setValue } = props
  return (
    <div className="mb-4 d-flex flex-column">
      <>      {label && (
        <ThemedLabel htmlFor={`${name}`} className=" mb-1">
          {label}
        </ThemedLabel>
      )}</>

      <ThemedInput
        type={`${type}`}
        name={`${name}`}
        placeholder={`${placeholder}`}
        value={value} 
        onChange = {(e)=>setValue && setValue(e.target.value)}
      />
    </div>
  )
}
