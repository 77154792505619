import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../../../assets/icons/Logo'
import {
  AuthPageWrapper,
  LargeHeader,
  MediumHeader,
  MediumParagraph,
  ThemedButton,
} from '../../../../assets/styles/styles'
import FormInputWithLabel from '../../../../components/FormInputWithLabel'
import { useUserStore } from '../../../../context/UserStoreContext'
import { mapThemeCodeToMessage } from '../../../../helpers/errorHandlers'
import {UserStoreContextProvider} from '../../../../context/UserStoreContext'
import ThemedFormButton from '../../../../components/ThemedFormButton'

export default function StoreName() {
  const { createStoreName } = useUserStore()
  const [storeName, setstoreName] = useState("")
  const [isLoading, setisLoading] = useState(false)
  const navigate = useNavigate() 
  const [error, setError] = useState('')
  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    try {
      setisLoading(true)
      if (!storeName || storeName=='') throw new Error('store name must not be empty')
      await createStoreName(null,storeName)
      setisLoading(false)

      navigate('/select-theme')
    } catch (err) {
      setError(mapThemeCodeToMessage(err.message)) 
      setisLoading(false)
    }
  }
 
  return (
    <>
      <AuthPageWrapper className="row justify-content-center pt-5">
        <div
          className="col-11 col-md-5 col-lg-4 "
          style={{ height: 'fit-content' }}
        >
          <div className="text-center flex-wrap">
            <Logo />

            <MediumHeader className="mt-5 mb-3">Welcome 👋</MediumHeader>
            <LargeHeader className="mt-3 mb-5">
              Enter your details to create an account.
            </LargeHeader>
          </div>

          <form onSubmit={handleSubmit}>
            {isLoading && <div>loading</div>}
            {error && (
              <div className="border border-danger rounded text-danger px-2 py-1 font-monospace text-center ">
                {error}
              </div>
            )}
            <FormInputWithLabel
              name="nameInput"
              placeholder="Enter the name of your store"
              type="text"
              value={storeName}
              setValue={setstoreName}
            />
 
            <ThemedFormButton isLoading={isLoading} placeholder="Continue"/>
          </form>
          <MediumParagraph className="text-center mt-4">
            By creating an account, <br />
            you agree to our{' '}
            <strong>
              <Link to="/terms">Term &amp; Conditions.</Link>
            </strong>
          </MediumParagraph>
        </div>
      </AuthPageWrapper>
    </>
  )
}
