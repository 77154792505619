import React from 'react'

function MinusIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7017 13.5869H5.70166V11.5869H19.7017V13.5869Z"
        fill="black"
      />
    </svg>
  )
}

export default MinusIcon
