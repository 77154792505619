import React from 'react'

function WalletIcon(props) {
  const { active,mobile } = props;
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill={active && mobile ? "#FF3365" : active ? "none" : "black"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3020_1305)">
        <path
          d="M10.0947 16.1865V8.18652C10.0947 7.08652 10.9847 6.18652 12.0947 6.18652H21.0947V5.18652C21.0947 4.08652 20.1947 3.18652 19.0947 3.18652H5.09473C3.98473 3.18652 3.09473 4.08652 3.09473 5.18652V19.1865C3.09473 20.2865 3.98473 21.1865 5.09473 21.1865H19.0947C20.1947 21.1865 21.0947 20.2865 21.0947 19.1865V18.1865H12.0947C10.9847 18.1865 10.0947 17.2865 10.0947 16.1865ZM13.0947 8.18652C12.5447 8.18652 12.0947 8.63652 12.0947 9.18652V15.1865C12.0947 15.7365 12.5447 16.1865 13.0947 16.1865H22.0947V8.18652H13.0947ZM16.0947 13.6865C15.2647 13.6865 14.5947 13.0165 14.5947 12.1865C14.5947 11.3565 15.2647 10.6865 16.0947 10.6865C16.9247 10.6865 17.5947 11.3565 17.5947 12.1865C17.5947 13.0165 16.9247 13.6865 16.0947 13.6865Z"
          fill={active && mobile ? "#FF3365" : active ? "white" : "black"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3020_1305">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.0947266 0.186523)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WalletIcon
