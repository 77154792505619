import React from 'react'; 
import MoreVertIcon from '../../assets/icons/MoreVertIcon';
import ThemedTableBodyItem from '../ThemedTableBodyItem/ThemedTableBodyItem';

export default function ThemedTableBody() {
  return ( 
    <>
    <ThemedTableBodyItem/>
   <ThemedTableBodyItem/>
   <ThemedTableBodyItem/>
   <ThemedTableBodyItem/>
   <ThemedTableBodyItem/>
   <ThemedTableBodyItem/>
   
   
   </>

  );
}
