import React from 'react'
import './index.css'
export default function DottedElipseLoader() {
  return (
    // <div class="lds-ellipsis">
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    // </div>

    // <div class="lds-default">
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    // </div>

    <div class="loader"></div>
  )
}
