import React from 'react'

function ProfileIcon(props) {
  const { active,mobile} = props;
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill={active && mobile ? "#FF3365" : active ? "none" : "black"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3020_1327)">
        <path
          d="M12.0947 12.1865C14.3047 12.1865 16.0947 10.3965 16.0947 8.18652C16.0947 5.97652 14.3047 4.18652 12.0947 4.18652C9.88473 4.18652 8.09473 5.97652 8.09473 8.18652C8.09473 10.3965 9.88473 12.1865 12.0947 12.1865ZM12.0947 14.1865C9.42473 14.1865 4.09473 15.5265 4.09473 18.1865V19.1865C4.09473 19.7365 4.54473 20.1865 5.09473 20.1865H19.0947C19.6447 20.1865 20.0947 19.7365 20.0947 19.1865V18.1865C20.0947 15.5265 14.7647 14.1865 12.0947 14.1865Z"
          fill={active && mobile ? "#FF3365" : active ? "white" : "black"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3020_1327">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.0947266 0.186523)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ProfileIcon
