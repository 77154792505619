import React from 'react'

function Notifications() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2418_4508" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
    <rect x="0.162598" y="0.485352" width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_2418_4508)">
    <path d="M4.1626 19.4854V17.4854H6.1626V10.4854C6.1626 9.10202 6.57926 7.87268 7.4126 6.79735C8.24593 5.72268 9.32926 5.01868 10.6626 4.68535V3.98535C10.6626 3.56868 10.8086 3.21468 11.1006 2.92335C11.3919 2.63135 11.7459 2.48535 12.1626 2.48535C12.5793 2.48535 12.9333 2.63135 13.2246 2.92335C13.5166 3.21468 13.6626 3.56868 13.6626 3.98535V4.68535C14.9959 5.01868 16.0793 5.72268 16.9126 6.79735C17.7459 7.87268 18.1626 9.10202 18.1626 10.4854V17.4854H20.1626V19.4854H4.1626ZM12.1626 22.4854C11.6126 22.4854 11.1419 22.2897 10.7506 21.8984C10.3586 21.5064 10.1626 21.0354 10.1626 20.4854H14.1626C14.1626 21.0354 13.9669 21.5064 13.5756 21.8984C13.1836 22.2897 12.7126 22.4854 12.1626 22.4854ZM8.1626 17.4854H16.1626V10.4854C16.1626 9.38535 15.7709 8.44369 14.9876 7.66035C14.2043 6.87702 13.2626 6.48535 12.1626 6.48535C11.0626 6.48535 10.1209 6.87702 9.3376 7.66035C8.55426 8.44369 8.1626 9.38535 8.1626 10.4854V17.4854Z" fill="#1C1B1F"/>
    </g>
    </svg>
    
  )
}

export default Notifications
