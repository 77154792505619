import { createContext, useContext, useEffect, useState } from 'react'

const userStoreContext = createContext()

export function UserStoreContextProvider({ children }) {
   let initialStores = JSON.parse(localStorage.getItem("userStores"))
   const [stores, setStores] = useState(initialStores||[])
  // to do
  // const [selectedStores, setselectedStores] = useState([]) to handle multiple store selection
  const [currentStore, setCurrentStore] = useState(null)
  function createStore(store) {
    store.id = stores.length + 1
    setStores(stores.concat(store))
    setCurrentStore(store)
    return store
  }

  function createStoreName(store, name) {
    if (!store) return initStoreWithData({ name })

    return addDataToSingleStore(store, { name })
  }
  function initStoreWithData(data) {
    createStore(data) 
    return data
  }
  function addDataToSingleStore(store, data) {  
    const { ...store_ } = store
    const { ...new_ } = data
    const newstore = { ...store_, ...new_ } 
    updateStore(newstore)
    return store
  }
  function updateStore(store) { 
    const newStore = stores.map((currentStore) => {
      if (currentStore.id == store.id) return store
      return currentStore
    
    })
    setStores(newStore)
    setCurrentStore(store) 
    return newStore
  }

  useEffect(() => { 
      console.warn('In userstorecontext use effect')
   localStorage.setItem("userStores",JSON.stringify(stores))
   
  //  localStorage.removeItem("userStores") 

    // return () => {
    //   unsubscribe()
    // }
  }, [stores])

  return (
    <userStoreContext.Provider value={{ stores, createStoreName,currentStore ,addDataToSingleStore}}>
      {children}
    </userStoreContext.Provider>
  )
}

export function useUserStore() {
  return useContext(userStoreContext)
}
