import React, { useState } from "react";
import Search from "../../assets/icons/Search"; 


export default function ThemedSearchBox(props) {
  const { right = false, placeholder = "" } = props;
  return (
    <div className=" align-items-center w-100">
      <div
        className={`border rounded-2 px-2 py-1  d-flex align-items-center ${
          right && "flex-row-reverse"
        }`}
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <input
          type="search"
          name=""
          id=""
          className="border-0 w-100  "
          style={{ background: " inherit" }}
          placeholder={placeholder}
        />
        <Search />
      </div>
    </div>
  );
}
