import React from 'react'

function Search() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2418_4515"
        style={{maskType:"alpha"}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="25"
      >
        <rect x="0.449707" y="0.436523" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2418_4515)">
        <path
          d="M20.0497 21.4365L13.7497 15.1365C13.2497 15.5365 12.6747 15.8532 12.0247 16.0865C11.3747 16.3199 10.683 16.4365 9.94971 16.4365C8.13304 16.4365 6.59571 15.8075 5.33771 14.5495C4.07904 13.2909 3.44971 11.7532 3.44971 9.93652C3.44971 8.11986 4.07904 6.58219 5.33771 5.32352C6.59571 4.06552 8.13304 3.43652 9.94971 3.43652C11.7664 3.43652 13.304 4.06552 14.5627 5.32352C15.8207 6.58219 16.4497 8.11986 16.4497 9.93652C16.4497 10.6699 16.333 11.3615 16.0997 12.0115C15.8664 12.6615 15.5497 13.2365 15.1497 13.7365L21.4497 20.0365L20.0497 21.4365ZM9.94971 14.4365C11.1997 14.4365 12.2624 13.9992 13.1377 13.1245C14.0124 12.2492 14.4497 11.1865 14.4497 9.93652C14.4497 8.68652 14.0124 7.62386 13.1377 6.74852C12.2624 5.87386 11.1997 5.43652 9.94971 5.43652C8.69971 5.43652 7.63704 5.87386 6.76171 6.74852C5.88704 7.62386 5.44971 8.68652 5.44971 9.93652C5.44971 11.1865 5.88704 12.2492 6.76171 13.1245C7.63704 13.9992 8.69971 14.4365 9.94971 14.4365Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  )
}

export default Search
