import React from 'react'

function SelectDropdown() {
  return (
    <svg
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.226562 0L7.72656 7.5L15.2266 0H0.226562Z" fill="black" />
    </svg>
  )
}

export default SelectDropdown
