import React from 'react';
import ThemedTableBody from '../ThemedTableBody/ThemedTableBody';
import ThemedTableHead from '../ThemedTableHead/ThemedTableHead';

export default function ThemedTable() {
  return (
 
    <div className="mt-2 mt-md-4">
    {/* <Table columns={columns} data={data} /> */}

    <ThemedTableHead/>
    <ThemedTableBody/>
  </div>

  );
}
