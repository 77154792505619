import React from 'react'
import Logo from '../../assets/icons/Logo'
import Notifications from '../../assets/icons/Notifications'
import Settings from '../../assets/icons/Settings'
import { useUserAuth } from '../../context/UserAuthContext'
import ThemedSearchBox from '../ThemedSearchBox'
import {
  LargeDashboardHeader,
  SmallDashboardHeader,
} from '../../assets/styles/styles'
import MenuIcon from '../../assets/icons/MenuIcon'
export default function NavBar() {
  const { user } = useUserAuth()
  return (
    <header
      className=" container-fluid d-flex justify-content-between align-items-center sticky-top px-3 shadow-sm"
      style={{ height: '7rem', }}
    >
      <div className="d-flex d-lg-none p-2">
        <MenuIcon />
      </div>
      <div
        className="d-none d-lg-flex justify-content-center align-items-center"
        style={{ width: '25rem' }}
      >
        <Logo />
      </div>
      <div
        className="d-block d-lg-flex justify-content-between py-0 py-md-4"
        style={{ flex: 6 }}
      >
        <div className="d-none d-lg-block">
          <LargeDashboardHeader className="m-0">
            Welcome {user?.displayName}
          </LargeDashboardHeader>
          <SmallDashboardHeader className="m-0">
            Sellers Dashboard
          </SmallDashboardHeader>
        </div>
        <div className="d-flex gap-3 gap-md-5 align-items-center justify-content-between ">
          <ThemedSearchBox />

          <div className="d-flex align-items-center gap-0 gap-md-3">
            <Notifications />
            <div className="d-none d-md-flex">
              {' '}
              <Settings />
            </div>{' '}
          </div>
        </div>
      </div>
    </header>
  )
}
