import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../../context/UserAuthContext";
import { useUserStore } from "../../../../context/UserStoreContext";
import { mapAuthCodeToMessage } from "../../../../helpers/errorHandlers";
import ThemedSearchBox from "../../../../components/ThemedSearchBox";
import Notifications from "../../../../assets/icons/Notifications";
import Settings from "../../../../assets/icons/Settings";
import {
  LargeCardHeader,
  LargeHeader,
  MediumParagraph,
  BoldCardSmallText,
  ListCardSmallText,
  LargeDashboardHeader,
} from "../../../../assets/styles/styles";
import ThemedTable from "../../../../components/ThemedTable/ThemedTable";
import Sidebar from "../../../../components/SideBar/SideBar";
import DashboardCard from "../../../../components/DashboardCard";

export default function Home() {
  const columns = useMemo(
    () => [
      {
        // first group - TV Show
        Header: "TV Show",
        // First group columns
        columns: [
          {
            Header: "Name",
            accessor: "show.name",
          },
          {
            Header: "Type",
            accessor: "show.type",
          },
        ],
      },
      {
        // Second group - Details
        Header: "Details",
        // Second group columns
        columns: [
          {
            Header: "Language",
            accessor: "show.language",
          },
          {
            Header: "Genre(s)",
            accessor: "show.genres",
          },
          {
            Header: "Runtime",
            accessor: "show.runtime",
          },
          {
            Header: "Status",
            accessor: "show.status",
          },
        ],
      },
    ],
    []
  );

  const { stores } = useUserStore();
  const { user } = useUserAuth();
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]);

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    (async () => {
      const result = await axios("https://api.tvmaze.com/search/shows?q=snow");
      setData(result.data);
    })();
  }, []);
  return isLoading ? (
    <div>loading</div>
  ) : (
    <>
      <LargeDashboardHeader className="mb-4 d-flex d-md-none">
        Product Dashboard
      </LargeDashboardHeader>
      <div className="row gap-3 gap-lg-4 d-none d-md-flex">
        <DashboardCard />
        <DashboardCard />
      </div>

      <ThemedTable />
    </>
  );
}
