import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../../context/UserAuthContext";
import { useUserStore } from "../../../../context/UserStoreContext";
import EmptyStoreLargeIcon from "../../../../assets/icons/EmptyStoreLargeIcon";
import {
  SmallDashboardHeader,
  TableContentBoldText,
  TableContentText,
} from "../../../../assets/styles/styles";

export default function Order() {
  const { stores } = useUserStore();
  const { user } = useUserAuth();
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]);

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    (async () => {
      const result = await axios("https://api.tvmaze.com/search/shows?q=snow");
      setData(result.data);
    })();
  }, []);

  return isLoading ? (
    <div>loading</div>
  ) : (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 shadow rounded-4 p-4 mb-3">
          <div className="d-flex justify-content-between align-items-end">
            <p>Order #1919</p>
            <h3>Order #1919</h3>
          </div>

          <p>
            <SmallDashboardHeader className="m-0">
              06 May, 2023 at 4:13pm
            </SmallDashboardHeader>
          </p>

          <div
            className="w-100 row p-3 rounded-3 m-0"
            style={{
              backgroundColor: "#F5F5F5",
            }}
          >
            <div className="col-12 col-md-2 rounded-2 overflow-hidden">
              <img src="/product1large.png" alt="" width="100%" />
            </div>

            <div className="col mt-3 mt-md-0">
              <TableContentBoldText>
                {" "}
                Non platea in mi dignissim vel pretium.
              </TableContentBoldText>
              <div className="row">
                <div className="col-5 col-md-2">
                  <TableContentText>Product ID</TableContentText>
                </div>
                <div className="col">
                  <TableContentText> 28282C </TableContentText>
                </div>
              </div>
              <div className="row">
                <div className="col-5 col-md-2">
                  <TableContentText>Quantity</TableContentText>
                </div>
                <div className="col">
                  <TableContentText> 12 </TableContentText>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 shadow rounded-4 p-4 mb-3">
          <div className="d-flex justify-content-between align-items-end">
            <p>Order #1919</p>
            <h3>Order #1919</h3>
          </div>

          <p>
            <SmallDashboardHeader className="m-0">
              06 May, 2023 at 4:13pm
            </SmallDashboardHeader>
          </p>

          <div
            className="w-100 row p-3 rounded-3 m-0"
            style={{
              backgroundColor: "#F5F5F5",
            }}
          >
            <div className="col-12 col-md-2 rounded-2 overflow-hidden">
              <img src="/product1large.png" alt="" width="100%" />
            </div>

            <div className="col mt-3 mt-md-0">
              <TableContentBoldText>
                {" "}
                Non platea in mi dignissim vel pretium.
              </TableContentBoldText>
              <div className="row">
                <div className="col-5 col-md-2">
                  <TableContentText>Product ID</TableContentText>
                </div>
                <div className="col">
                  <TableContentText> 28282C </TableContentText>
                </div>
              </div>
              <div className="row">
                <div className="col-5 col-md-2">
                  <TableContentText>Quantity</TableContentText>
                </div>
                <div className="col">
                  <TableContentText> 12 </TableContentText>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
