import React from 'react'

function PlusIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7017 8.58691H8.70166V14.5869H6.70166V8.58691H0.70166V6.58691H6.70166V0.586914H8.70166V6.58691H14.7017V8.58691Z"
        fill="black"
      />
    </svg>
  )
}

export default PlusIcon


