import React, { useRef, useState } from "react";
import {
  WalletListButtonText,
  TableContentText,
  WalletListText,
} from "../../assets/styles/styles";
export default function WalledCardListItem() {
  const styles= {
    boxShadow: ".1rem .1rem .5rem0rem #00000012"

  }
  return (
    <li className="  p-1 d-flex justify-content-between align-items-center p-1 mb-2 rounded" style={styles}>
      <span>
        {" "}
        <WalletListText className="m-0 text-center">24-04</WalletListText>
      </span>
      <span>
        <TableContentText className="m-0 text-center">
          Product purchase
        </TableContentText>
      </span>
      <span className="border rounded p-2">
        {" "}
        <WalletListButtonText className="m-0 text-center">
          Expense
        </WalletListButtonText>
      </span>
    </li>
  );
}
