import React, { useMemo, useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { useUserAuth } from '../../../../context/UserAuthContext'
import { useUserStore } from '../../../../context/UserStoreContext'
import { mapAuthCodeToMessage } from '../../../../helpers/errorHandlers'
import ThemedSearchBox from '../../../../components/ThemedSearchBox'
import Notifications from '../../../../assets/icons/Notifications'
import Settings from '../../../../assets/icons/Settings'
import {
  LargeCardHeader,
  LargeHeader,
  MediumParagraph,
  BoldCardSmallText,
  ListCardSmallText,
  ThemeCardWrapper,
  ThemedCancelButton,
} from '../../../../assets/styles/styles'
import ThemedTable from '../../../../components/ThemedTable/ThemedTable'
import Sidebar from '../../../../components/SideBar/SideBar'
import DashboardCard from '../../../../components/DashboardCard'
import EmptyStoreLargeIcon from '../../../../assets/icons/EmptyStoreLargeIcon'
import NavBar from '../../../../components/NavBar/NavBar'
import ThemedFormButton from '../../../../components/ThemedFormButton'
import ThemedFormRemoveButton from '../../../../components/ThemedFormRemoveButton'

export default function ProductItem() {
  const { stores } = useUserStore()
  const { user } = useUserAuth()
  const [isLoading, setisLoading] = useState(false)
  const navigate = useNavigate()
  const [error, setError] = useState('')

  // data state to store the TV Maze API data. Its initial value is an empty array
  const [selectedImages, setSelectedImages] = useState({})
  const selectProductImage = (productImage) => {
    console.log(productImage)
    const { id, url } = productImage
    setSelectedImages((data) => ({ ...data, [id]: url }))
  }
  const unselectProductImage = (productImage) => {
    // console.log(productImage)
    const { id, url } = productImage
  const data = {...selectedImages, [id]: null}
    setSelectedImages(data)
  }
  const selectedProduct = {
    name: 'Non platea in mi dignissim vel pretium.',
    productId: '28282C',
    descrption: 'loren ipsum hun jsd agd akw sfs;s;d',
    price: '500',
    quantity: '30',
    review: 'good product',
    images: [
      {
        id: 1,
        url: '/product1.png',
      },
      {
        id: 2,
        url: '/product1.png',
      },
      {
        id: 3,
        url: '/product1.png',
      },
      {
        id: 4,
        url: '/product1.png',
      },
      {
        id: 5,
        url: '/product1.png',
      },
    ],
  }

  const { name, productId, descrption, price, quantity, review, images = [] } =
    selectedProduct || {}
  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    ;(async () => {
      const result = await axios('https://api.tvmaze.com/search/shows?q=snow')
    })()
  }, [])
  console.log(selectedImages)
  return isLoading ? (
    <div>loading</div>
  ) : (
    <div
      className="d-flex  flex-column p-5"
      style={{
        backgroundColor: '#F5F5F5',
        minHeight: '100vh',
        borderRadius: '2.5rem',
      }}
    >
      <div className="col-12 col-md-8 col-lg-10">
        <div className="row">
          <div className="col-12 col-md-6 rounded overflow-hidden">
            <img src="/product1large.png" alt="" width="100%" />
          </div>
        </div>
        <div className="">
          <ThemeCardWrapper className="d-flex px-1 py-2   py-md-3 gap-2 flex-wrap">
            {images.map((image, index) => {
              return (
                <div
                  key={index}
                  className=" me-1"
                  onClick={() => {
                    !selectedImages[image.id] ?
                    selectProductImage(image): unselectProductImage(image)
                  }}
                >
                  <div
                    className={` ${
                      selectedImages[image.id] && 'border'
                    }  rounded-4 p-2`}
                    style={{
                      height: '8rem',
                      width: '8rem',
                      transform: 'scale(1.03)',
                    }}
                  >
                    <div
                      className="border rounded-4 h-100  "
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <img
                        src="/product1.png"
                        alt=""
                        height="100%"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </ThemeCardWrapper>
          <div className="row">
            <div className="col-5">Product Name</div>
            <div className="col-7">{name}</div>
            <div className="col-5">Product ID</div>
            <div className="col-7">{productId}</div>
            <div className="col-5">Product Description</div>
            <div className="col-7">{descrption}</div>
            <div className="col-5">Price</div>
            <div className="col-7">{price}</div>
            <div className="col-5">Quantity</div>
            <div className="col-7">{quantity}</div>
            <div className="col-5">Review</div>
            <div className="col-7">{review}</div>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <div className="col-12 col-md-7 d-block d-md-flex gap-2 float-end ">
          <ThemedFormRemoveButton placeholder="Remove Product" />
          <ThemedFormButton placeholder="Edit Product" />
        </div>
      </div>
    </div>
  )
}
