import { createContext, useContext, useEffect, useState } from 'react'
import { storeProductCategories } from '../assets/data'

const userProductCategoryContext = createContext()
export function UserProductCategoryProvider({ children }) {
  const [productCategories, setproductCategories] = useState([])

  useEffect(() => {
    const unsubscribe = () => {
      setproductCategories(storeProductCategories)
    }

    return () => {
      unsubscribe()
    }
  })

  return (
    <userProductCategoryContext.Provider value={{ productCategories }}>
      {children}
    </userProductCategoryContext.Provider>
  )
}

export function useStoreProductCategory() {
  return useContext(userProductCategoryContext)
}
