import { useEffect } from "react";
import WebFont from "webfontloader";
import {
  UserAuthContextProvider,
  useUserAuth,
} from "./context/UserAuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import SignUp from "./pages/UnAuthenticated/SignUp";
import LogIn from "./pages/UnAuthenticated/LogIn";
import StoreName from "./pages/Authenticated/Store/StoreName";
import SelectTheme from "./pages/Authenticated/Store/SelectTheme";
import SelectProduct from "./pages/Authenticated/Store/SelectProduct";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import AddWelcomeText from "./pages/Authenticated/Store/AddWelcomeText";
import { UserStoreContextProvider } from "./context/UserStoreContext";
import { UserThemeContextProvider } from "./context/UserThemeContext";
import { UserProductCategoryProvider } from "./context/UserProductCategoryContext";
import Home from "./pages/Authenticated/Store/Home";
import Store from "./pages/Authenticated/Store/Store";
import CreateStore from "./pages/Authenticated/Store/CreateStore";
import Sidebar from "./components/SideBar/SideBar";
import NavBar from "./components/NavBar/NavBar";
import Products from "./pages/Authenticated/Store/Products";
import CreateProduct from "./pages/Authenticated/Store/CreateProduct";
import { GridContainer } from "./assets/styles/styles";
import ProductItem from "./pages/Authenticated/Store/ProductItem";
import Order from "./pages/Authenticated/Store/Order";
import Wallet from "./pages/Authenticated/Store/Wallet";
import Landing from "./pages/Landing";
import Profile from "./pages/Authenticated/Store/Profile";
function App() {
  const { user } = useUserAuth();
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Lato", "Inter", "Jost", "Open Sans", "Helvetica", "Mulish"],
      },
    });
  });

  const Layout = () => {
    return (
      <GridContainer className="d-lg-block">
        <NavBar />
        <main className="d-flex pt-3 ">
          <div
            className="container-fluid d-none d-lg-flex"
            style={{ width: "250px" }}
          >
            <Sidebar />
          </div>
          <div
            className=" container-fluid px-4 py-0 py-md-3"
            style={{ flex: 6, overflowY: "scroll !important" }}
          >
            <Outlet />
          </div>
        </main>
        <footer className="d-flex d-lg-none">
          <Sidebar />
        </footer>
      </GridContainer>
    );
  };

  return (
    <>
      <UserStoreContextProvider>
        <UserThemeContextProvider>
          <UserProductCategoryProvider>
            {" "}
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<LogIn />} />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Layout />
                  </ProtectedRoute>
                }
              >
                <Route
                  path="/home"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/store"
                  element={
                    <ProtectedRoute>
                      <Store />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/products"
                  element={
                    <ProtectedRoute>
                      <Products />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/products/:id"
                  element={
                    <ProtectedRoute>
                      <ProductItem />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/products/create"
                  element={
                    <ProtectedRoute>
                      <CreateProduct />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/store/create"
                  element={
                    <ProtectedRoute>
                      <CreateStore />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/orders"
                  element={
                    <ProtectedRoute>
                      <Order />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/wallet"
                  element={
                    <ProtectedRoute>
                      <Wallet />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <Profile />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="/store-name"
                element={
                  <ProtectedRoute>
                    <StoreName />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/select-theme"
                element={
                  <ProtectedRoute>
                    <SelectTheme />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/select-product"
                element={
                  <ProtectedRoute>
                    <SelectProduct />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/add-welcome-text"
                element={
                  <ProtectedRoute>
                    <AddWelcomeText />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <div>
                      <Navigate to="/store-name" />{" "}
                    </div>
                  </ProtectedRoute>
                }
              />
            </Routes>
          </UserProductCategoryProvider>
        </UserThemeContextProvider>
      </UserStoreContextProvider>
    </>
  );
}

export default App;
