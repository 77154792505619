export const storeSteps = [
  {
    id: 1,
    title: "Create Sellers Account.",
    details: "Add product details and start selling.",
  },
  {
    id: 2,
    title: "Upload Store  Details.",
    details: "Add product details and start selling.",
  },
  {
    id: 3,
    title: "Upload Product Details.",
    details: "Add product details and start selling.",
  },
];
export const serviceSteps = [
  {
    id: 1,
    imgUrl: " /unsplash_Q59HmzK38eQ.png ",
    title: "Fast and Secured transaction.",
    details:
      "In amet dolor faucibus ullamcorper. Id massa quam aliquam ut phasellus. Feugiat at cursus dolor, augue sit. At quam dignissim feugiat a nunc id dignissim tempor. In pellentesque purus in malesuada.",
  },
  {
    id: 2,
    imgUrl: "/assfdgdfgs.png",
    title: "Fast and Secured transaction.",
    details:
      "In amet dolor faucibus ullamcorper. Id massa quam aliquam ut phasellus. Feugiat at cursus dolor, augue sit. At quam dignissim feugiat a nunc id dignissim tempor. In pellentesque purus in malesuada.",
  },
  {
    id: 3,
    imgUrl: "/unsplash_tR0jvlsmCuQ.png",
    title: "Easy to Manage Database",
    details:
      "In amet dolor faucibus ullamcorper. Id massa quam aliquam ut phasellus. Feugiat at cursus dolor, augue sit. At quam dignissim feugiat a nunc id dignissim tempor. In pellentesque purus in malesuada.",
  },
];
