import React from "react";
import {
  BoldCardSmallText,
  LargeCardHeader,
  ListCardSmallText,
  MediumParagraph,
  SmallDashboardHeader,
} from "../../assets/styles/styles";

export default function AvatarWrapper(props) {
  const { name, size } = props;
  const styles = {
    height: size + "rem" || "8rem",
    width: size + "rem" || "8rem",
    overflow: "hidden",
    borderRadius: "50%",
    backgroundColor: "#000000",
  };
  return (
    <div className="">
      {" "}
      <div className="mb-1" style={styles}>
        <img
          src="/avatar_95х95.png"
          alt=""
          width="100%"
          style={{ objectFit: "cover" }}
        />
      </div>
      <div className="">
        {name && (
          <SmallDashboardHeader className="m-0 text-center">
            {name}
          </SmallDashboardHeader>
        )}{" "}
      </div>
    </div>
  );
}
