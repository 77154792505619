import React from 'react'

function EmptyStoreLargeIcon() {
  return (
    <svg
      width="217"
      height="217"
      viewBox="0 0 217 217"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{maxWidth: "20rem"}}
    >
      <g clipPath="url(#clip0_2937_8151)">
        <path
          d="M182.273 70.5542C181.463 66.4136 177.772 63.3532 173.451 63.3532H44.1942C39.8736 63.3532 36.1831 66.4136 35.373 70.5542L27.812 108.359V117.36C27.812 122.311 31.8626 126.362 36.8132 126.362V171.368C36.8132 176.318 40.8638 180.369 45.8144 180.369H117.824C122.775 180.369 126.825 176.318 126.825 171.368V126.362H162.83V171.368C162.83 176.318 166.881 180.369 171.831 180.369C176.782 180.369 180.832 176.318 180.832 171.368V126.362C185.783 126.362 189.834 122.311 189.834 117.36V108.359L182.273 70.5542ZM108.823 162.366H54.8156V126.362H108.823V162.366ZM45.8144 54.352H171.831C176.782 54.352 180.832 50.3015 180.832 45.3508C180.832 40.4001 176.782 36.3496 171.831 36.3496H45.8144C40.8638 36.3496 36.8132 40.4001 36.8132 45.3508C36.8132 50.3015 40.8638 54.352 45.8144 54.352Z"
          fill="#F17997"
        />
      </g>
      <circle
        cx="174.82"
        cy="169.488"
        r="31.3522"
        fill="#F5F5F5"
        stroke="#F17997"
        strokeWidth="11.9476"
      />
      <path
        d="M162.872 157.54L186.767 181.435"
        stroke="#F17997"
        strokeWidth="11.9476"
        strokeLinecap="round"
      />
      <path
        d="M186.767 157.54L162.872 181.435"
        stroke="#F17997"
        strokeWidth="11.9476"
        strokeLinecap="round"
      />
      <defs>
        <clipPath id="clip0_2937_8151">
          <rect
            width="216.029"
            height="216.029"
            fill="white"
            transform="translate(0.808594 0.345703)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EmptyStoreLargeIcon
