import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../../assets/icons/Logo'
import {
  MediumHeader,
  MediumParagraph,
  ThemedButton,
  AuthPageWrapper,
} from '../../../assets/styles/styles'
import FormInputWithLabel from '../../../components/FormInputWithLabel'
import RememberMe from '../../../components/RememberMe'
import ThemedFormButton from '../../../components/ThemedFormButton'
import { useUserAuth } from '../../../context/UserAuthContext'
import { mapAuthCodeToMessage } from '../../../helpers/errorHandlers'

export default function LogIn() {
  const { logIn } = useUserAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setisLoading] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    try { 
      setisLoading(true)
      await logIn(email, password)
      setisLoading(false)

      navigate('/home')
    } catch (err) {
      setError(mapAuthCodeToMessage(err.code))
      console.warn(err)
      setisLoading(false)
    }
  }

  const navigate = useNavigate()
  return (
    <>
      <AuthPageWrapper className="container-fluid row justify-content-center pt-5">
        <div
          className="col-11 col-md-5 col-lg-4 "
          style={{ height: 'fit-content' }}
        >
          <div className="text-center">
            <Logo onClick={()=>{navigate("/")}} />

            <MediumHeader className="mt-5 mb-3">Welcome Back 👋</MediumHeader>
            <MediumParagraph>
              Enter your details to sign in to your account.
            </MediumParagraph>
          </div>

          <form onSubmit={handleSubmit} >
            {/* {isLoading && <div>loading</div>} */}
            {error && (
              <div className="border border-danger rounded text-danger px-2 py-1 font-monospace text-center bg-danger bg-opacity-25">
                {error}
              </div>
            )}
            <FormInputWithLabel
              name="emailInput"
              placeholder="Your email"
              type="email"
              label="Email"
              value={email}
              setValue={setEmail}
            />
            <FormInputWithLabel
              name="passwordInput"
              placeholder="enter your password"
              type="password"
              label="Password"
              value={password}
              setValue={setPassword}
            />
            <RememberMe />


            <ThemedFormButton isLoading={isLoading} placeholder="Log In"/>
            
          </form>
          <MediumParagraph className="text-center mt-4">
            Don't have an account?{' '}
            <strong>
              <Link to="/signup"> Sign Up</Link>
            </strong>
          </MediumParagraph>
        </div>
      </AuthPageWrapper>
    </>
  )
}
