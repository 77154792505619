import Cart from "../../assets/icons/Cart";
import Help from "../../assets/icons/Help";
import Logo from "../../assets/icons/Logo";
import { serviceSteps, storeSteps } from "../../assets/pageData/data";
import MessageBox from "../../assets/icons/MessageBox";
import {
  IntroBody,
  IntroButton,
  IntroTitle,
  SellerSectionBody,
  SellerSectionButton,
  SellerSectionTitle,
  SendMailButton,
} from "../../assets/styles/styles";
import FeaturesCardLeft from "../../components/FeaturesCardLeft";
import FeaturesCardRight from "../../components/FeaturesCardRight";
import InfoCard from "../../components/InfoCard"; 
import ThemedSearchBox from "../../components/ThemedSearchBox";
import { useNavigate } from "react-router-dom";
 

function Landing() {
  const navigate = useNavigate()
  return (
    <div className="">
      <nav className="m-0 d-flex justify-content-between align-items-center px-4 py-4 sticky-top bg-white ">
        <Logo />
        <div className="d-none d-md-flex w-50">
          <ThemedSearchBox right />{" "}
        </div>
        <div className="d-flex gap-5 align-items-center">
          <Help /> <Cart />{" "}
          <div style={{ width: "30px", height: "30px" }}>
            <img src="/avatar_95х95.png" width="100%" />
          </div>
        </div>
      </nav>
      <main className="">
        <section className="container-fluid first-sect p-5">
          <div className="py-5 text-center d-flex align-items-center justify-content-center mt-3 ">
            <div className="col-12 col-md-7 col-lg-4">
              <IntroTitle className="mb-4">
                Sell Your Products in 3 Easy Steps
              </IntroTitle>
              <IntroBody className="mb-4">
                Let us help you showcase your Business to the World
              </IntroBody>
              <IntroButton className="shadow" onClick={()=>{navigate("/signup")}}>START SELLING</IntroButton>
            </div>
          </div>
          <div className="d-flex  align-items-center justify-content-center row flex-wrap py-3 p-4">
            {storeSteps.map((data, index) => {
              return <InfoCard {...data} />;
            })}
          </div>
        </section>
        <section className="bg-lunnex-blue sellers-sect px-3 pt-5 container-fluid ">
          <div className="row">
            <div className="col-12 col-md-5 px-4 px-md-5 d-flex align-items-center">
              <div>
                <SellerSectionTitle className="my-5">
                  Sell from the Confort of your room.
                </SellerSectionTitle>
                <SellerSectionBody className="mb-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Posuere nascetur iaculis feugiat vel. Nisi facilisis ultrices
                  velit id orci blandit purus.
                </SellerSectionBody>
                <SellerSectionButton className="shadow" onClick={()=>{navigate("/signup")}}>
                  START SELLING
                </SellerSectionButton>
              </div>
            </div>
            <div className="col-12 col-md-7 p-0">
              <img
                className=""
                src="/unsplash_hflLf87Aer0.png"
                width="100%"
                // height="100%"
                style={{ width: "100%", height: "auto", maxHeight: "100%" }}
              />
            </div>
          </div>
        </section>
        <section className="container-fluid mt-5 py-5  px-md-5  mx-lg-0">
          {serviceSteps.map((data,index) => {
            return( index %2 == 0 ? <FeaturesCardRight {...data} />: 
            <FeaturesCardLeft {...data} />)
          })} 
        </section>
        <section className="container-fluid bg-lunnex-blue about-sect p-5">
          <div className="row align-items-center justify-content-center ">
            <div className="col-12 col-md-6 mb-4">
              <p style={{ color: "#fff" }}>
                Want to know more about our dicounts and updates? Subscribe to
                our e-mail newsletters!
              </p>
            </div>

            <div className="col-12 col-md-9">
              <div className="d-flex gap-4 mb-3">
                <div
                  className="p-3  px-4 rounded d-flex align-items-center w-100 gap-1  "
                  style={{ background: "#fff" }}
                >
                  <MessageBox />
                  <input
                    type="mail"
                    placeholder="Enter E-mail Address"
                    className="w-100 border-0"
                  />
                </div>
                <SendMailButton className="shadow " onClick={()=>{navigate("/signup")}}>Submit</SendMailButton>
              </div>
            </div>

            <div className="col-12 col-md-8 text-white py-5">
              <h6 className="mb-4">SHORT LINKS</h6>
              <div className="row">
                <div className="col-6">
                  <ul className="list-unstyled">
                    <li>How to shop on lunnex?</li>
                    <li>Corporate and bulk purchases</li>
                    <li>How to return a product on lunnex?</li>
                    <li>Delivery options and timeline</li>
                    <li>FAQ</li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="list-unstyled">
                    <li>How do I get my Vouchers?</li>
                    <li>How to withdraw from Lunnex wallet?</li>
                    <li>How to track my purchase?</li>
                    <li>Lunnex Refferal Program</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 text-white">
              <h6 className="mb-4">ABOUT LUNNEX</h6>
              <ul className="list-unstyled">
                <li>About us</li>
                <li>Contact us</li>
                <li>Text center</li>
                <li>Privacy and policy</li>
                <li>Terms and conditions</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="copywrite d-flex text-center justify-content-center p-3">
          Copyright@2021. Lunnex incorporates
        </section>
      </main>
    </div>
  );
}

export default Landing;
