import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "../../assets/icons/HomeIcon";
import Logo from "../../assets/icons/Logo";
import OrderIcon from "../../assets/icons/OrderIcon";
import ProductIcon from "../../assets/icons/ProductIcon";
import ProfileIcon from "../../assets/icons/ProfileIcon";
import StoreIcon from "../../assets/icons/StoreIcon";
import WalletIcon from "../../assets/icons/WalletIcon";
import { TableContentText } from "../../assets/styles/styles";

export default function Sidebar() {
  const navLinks = [
    {
      name: "Home",
      to: "home",
      mobile:true,
      icon: (props) => <HomeIcon {...props} />,
    },
    {
      name: "Product",
      to: "products",
      mobile:true,
      icon: (props) => <ProductIcon {...props} />,
    },
    {
      name: "Wallet",
      to: "wallet",
      mobile:true,
      icon: (props) => <WalletIcon {...props} />,
    },
    {
      name: "Store",
      to: "store",
      mobile:true,
      icon: (props) => <StoreIcon {...props} />,
    },
    {
      name: "Orders",
      to: "orders",
      mobile:false,
      icon: (props) => <OrderIcon {...props} />,
    },
    {
      name: "Profile",
      to: "profile",
      mobile:true,
      icon: (props) => <ProfileIcon {...props} />,
    },
  ];
  const location = useLocation().pathname.split("/")[1];
  console.log(location);
  const SideNavLink = (props) => {
    const { name, to, icon, active } = props;

    const [isHovered, setisHovered] = useState(false);
    return (
      <li
        className={`mb-2 rounded-4 ${
          (active || isHovered) && "side-nav-active"
        } `}
        style={{
          cursor: "pointer",
          font: "Lato",
          fontWeight: "600",
        }}
        onMouseEnter={() => {
          setisHovered(true);
        }}
        onMouseLeave={() => {
          setisHovered(false);
        }}
      >
        <Link className="row p-3" to={to}>
          <span className="col-4">{icon({ active: isHovered || active })}</span>{" "}
          <span className="col-8"> {name}</span>
        </Link>
      </li>
    );
  };
  const SideNavMobileLink = (props) => {
    const { name, to, icon, active } = props;

    const [isHovered, setisHovered] = useState(false);
    return (
      <li
      className={` ${
        (active || isHovered) && "side-nav-mobile-active"
      } `}
        style={{ cursor: "pointer", font: "Lato", fontWeight: "600" }}
        onMouseEnter={() => {
          setisHovered(true);
        }}
        onMouseLeave={() => {
          setisHovered(false);
        }}
      >
        <Link
          className="d-flex flex-column align-items-center gap-1 p-2"
          to={to}
        >
          <span className="">
            {icon({ active: isHovered || active, mobile: true })}
          </span>{" "}
          <TableContentText className=" "> {name}</TableContentText>
        </Link>
      </li>
    );
  };
  return (
    <>
      <div className="px-4  py-4 d-none d-lg-block w-100">
        <div
          className="py-5 px-5 m-0 m-auto position-fixed  "
          style={{
            backgroundColor: "#F5F5F5",
            borderRadius: "2.5rem",
            height: "calc(100vh - 16rem)",
            minWidth: "20rem",
          }}
        >
          <ul className="mt-4 list-unstyled">
            {navLinks.map((data, index) => {
              if (data.to.includes(location)) data = { ...data, active: true };

              return <SideNavLink {...data} />;
            })}
          </ul>
        </div>
      </div>

      <div
        className=" px-4 m-0 d-flex d-lg-none w-100  shadow-lg m-0"
        // style={{   height:"" }}
      >
        <ul className=" list-unstyled d-flex justify-content-between w-100 m-0 align-items-center">
          {navLinks.map((data, index) => {
            if (data.to.includes(location)) data = { ...data, active: true };

            if(data.mobile) return( <SideNavMobileLink {...data} />);
          })}
        </ul>
      </div>
    </>
  );
}
