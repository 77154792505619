import { createContext, useContext, useEffect, useState } from 'react'
import { storeThemes } from '../assets/data'
import { useUserStore } from './UserStoreContext'

const userThemeContext = createContext() 
export function UserThemeContextProvider({ children }) {
  const [themes, setThemes] = useState([ 
  ])
 

  useEffect(() => {
    const unsubscribe = () => {
      setThemes(storeThemes)
    }

    return () => {
      unsubscribe()
    }
  })

  return (
    <userThemeContext.Provider value={{ themes, }}>
      {children}
    </userThemeContext.Provider>
  )
}

export function useUserTheme() {
  return useContext(userThemeContext)
}
