export function mapAuthCodeToMessage(authCode) {
  switch (authCode) {
    case 'auth/invalid-password':
      return 'Password provided is not correct'

    case 'auth/invalid-email':
      return 'Email provided is invalid'
    case 'auth/missing-email':
      return 'Email Field is missing'
    case 'auth/missing-password':
      return 'Password Field is missing'
    case 'auth/network-request-failed':
      return 'network request failed'
    case 'auth/email-already-in-use':
      return 'email already in use'
    case 'auth/weak-password':
      return 'Password should be at least 6 characters'
    case 'auth/user-not-found':
      return 'user not found'
      case 'auth/wrong-password':
        return 'wrong password'
        case 'auth/too-many-requests':
          return 'too many requests, try again later'


     
    // Many more authCode mapping here...

    default:
      return 'error'
  }
}
export function mapThemeCodeToMessage(code) {
  switch (code) {
    case code:
      return code

    // Many more authCode mapping here...

    default:
      return 'error'
  }
}
