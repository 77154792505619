import React from 'react';

export default function ThemedTableHead() {
  return (
 
    <div
      className="row text-center align-items-center py-4 rounded-2 shadow-sm d-none d-md-flex"
      style={{
        background: '#D0D0FB',
        fontSize: '1.4rem',
        fontWeight: '600',
      }}
    >
      <div className="col-2 text-break">Image</div>
      <div className="col-2 text-break">Product Name</div>
      <div className="col-2 text-break">Product ID</div>
      <div className="col-1 text-break">Price</div>
      <div className="col-1 text-break">Quantity</div>
      <div className="col-1 text-break">Sold</div>
      <div className="col-2 text-break">Review</div>
      <div className="col-1 text-break">.</div>
    </div>
  
  );
}
