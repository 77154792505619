import styled from "styled-components";





// landing page

 


const IntroTitle = styled.h1` 
  word-break: break-word;
  font-size: 3.8rem;
  font-weight: 700;
  font-family: "Lato";
`;
const IntroBody = styled.p` 
  word-break: break-word;
  font-size: 2rem;
  font-weight: 500;
  font-family: "Lato";
`;
const IntroButton = styled.button`
  color: #fff;
  background: #4b4ded;
  border:none;
  border-radius:8px;
  padding: 10px 20px;
  font-weight : 700;
  font-family: "Work Sans";
  font-size: 16px; 
  box-shadow: 0px 6px 2px -4px #0e0e2c1a;

  box-shadow: 0px -1px 0px 0px #0e0e2c66 inset;
`;

const InfoCardWrapper = styled.div`
  position: relative;
  background: #ff3365;
  border-radius: 32px;
  height: 250px;
  // width: 100%;
  overflow: hidden;
`;
const InfoCardCircle = styled.div`
  position: absolute;
  border-radius: 50%;
  text-align: center;
  height: 17rem;
  border: 10px solid #fff;
  font-family: "Lato";
  width: 17rem;
  top: -5.5rem;
  right: -5.5rem;
`;

const InfoCardNumber = styled.h1`
  color: #fff;
  position: absolute;
  top: 10px;
  font-family: "Lato";
  right: 40px;
  font-size: 6rem;
`;

const InfoCardTitle = styled.h1`
  color: #fff;
  width: 60%;
  margin-top: 50px;
  font-family: "Lato";
  word-break: break-word;
  font-size: 2.7rem;
`;
const InfoCardBody = styled.p`
  color: #fff;
  width: 60%;
  font-weight:700;
  word-break: break-word;
  font-family: "Jost";
  font-size: 14px;
`;



const SellerSectionTitle = styled.h1` 
  word-break: break-word;
  font-size: 40px;
  font-family: "Lato";
  color: #fff;
  font-weight: 700;
`;
const SellerSectionBody = styled.p` 
  word-break: break-word;
  font-size: 16px;
  font-family: "Jost";
  font-weight: 300;
  color:#fff;
`;

const SellerSectionButton = styled.button`
  color: #4B4DED;
  background: #fff;
  border:none;
  border-radius:8px;
  padding: 10px 20px;
  font-family: "Work Sans";
  font-weight : 700;
  font-size: 16px; 
  box-shadow: 0px 6px 2px -4px #0e0e2c1a;

  box-shadow: 0px -1px 0px 0px #0e0e2c66 inset;
`;



const AboutCardContainer = styled.div` 
  margin-bottom: 20%;
  opacity:.85;
  @media (max-width: 768px) {
    margin-bottom: 5%;
  }
`;

const AboutCardTitle = styled.h3` 
  word-break: break-word;
  font-size: 2.4rem;
  margin-bottom: 1rem;
  font-family: "Lato";
  color: #fff;
  font-weight: 700;
`;
const AboutCardBody = styled.p` 
  word-break: break-word;
  font-size: 1.3rem;
  font-weight: 300;
  font-family: "Jost";
  color:#fff;
`;


const SendMailButton = styled.button`
  color: #FFF;
  background: #FF3365;
  border:none;
  border-radius:8px;
  padding: 10px 20px;
  font-weight : 700;
  font-family: "Helvetica";
  font-size: 16px; 
  box-shadow: 0px 6px 2px -4px #0e0e2c1a;

  box-shadow: 0px -1px 0px 0px #0e0e2c66 inset;
`;
 


// ******************
const GridContainer = styled.div`
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";

  grid-template-columns: 1fr;
  grid-template-rows: 7.8rem 1fr 6rem;
  min-height: -webkit-fill-available;
  height: 100dvh;
  overflow: hidden;
  /* overflow: hidden; */

  header {
    grid-area: header;
    background-color: #ffffff;
  }
  main {
    grid-area: main;
    padding: 0;
    padding-top: 0;
    padding-left: 0;
    overflow-y: scroll;
    width: 100%;
    height: 100% !important;
  }
  footer {
    grid-area: footer;
    /* background-color: #203040;
    color: #ffffff; */
  }
`;
const AuthPageWrapper = styled.div`
  height: 100vh !important;
  overflow: "hidden";
`;

const ThemedButton = styled.button`
  border: 0;
  color: #fff;
  background-color: #000000;
  display: inline-block;
  padding: 1.2rem 2.4rem;
  font-size: 1.4rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  border-radius: .8rem;
  margin-top: .5rem;
  width: 100%;
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  /* cursor: pointer; */
`;

const ThemedWalletButton = styled.button`
  border: 0;
  color: #fff;
  background-color: #26264c;
  display: inline-block;
  padding: 1.2rem 2.4rem;
  font-size: 1.4rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  border-radius: .8rem;
  margin-top: .5rem;
  width: 100%;
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  /* cursor: pointer; */
`;
const ThemedWalletTaskButton = styled.button`
  border: 0;
  color: #fff;
  background-color: #ff3365;
  display: inline-block;
  padding: 1.2rem 2.4rem;
  font-size: 1.4rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  border-radius: .8rem;
  margin-top: .5rem;
  width: 100%;
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  /* cursor: pointer; */
`;

const ThemedEditButton = styled.button`
  border: .1rem solid #000000;
  color: #000000;
  /* background-color: #000000; */
  display: inline-block;
  padding: 1.2rem 2.4rem;
  font-size: 1.4rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  border-radius: .8rem;
  margin-top: .5rem;
  width: 100%;
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  /* cursor: pointer; */
`;
const ThemedCancelButton = styled.button`
  border: 0;
  color: #d03131;
  background-color: #fff;
  display: inline-block;
  padding: 1.2rem 2.4rem;
  font-size: 1.4rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  border-radius: .8rem;
  margin-top: .5rem;
  width: 100%;
  cursor: pointer;
`;
const ThemedInput = styled.input`
  border: .1rem solid #d4d4d4;
  display: inline-block;
  padding: 1.2rem 2.4rem;
  font-size: 1.4rem;
  border-radius: .8rem;
  margin-top: .5rem;
  // width: 100%;
`;
const ThemedDivInput = styled.div`
  border: .1rem solid #d4d4d4;
  display: inline-block;
  padding: 1.2rem 2.4rem;
  font-size: 1.4rem;
  border-radius: .8rem;
  margin-top: .5rem;
  /* width: 100%; */
`;
const ThemedDivNumberInput = styled.div`
  border: .2rem solid #000000;
  display: inline-block;
  /* padding: 1.2rem 2.4rem; */
  font-size: 1.4rem;
  border-radius: .3rem;
  /* margin-top: .5rem; */
  /* width: 100%; */
`;
const ThemedRectangleInput = styled.input`
  border: .1rem solid #d4d4d4;
  display: inline-block;
  padding: 1.2rem 2.4rem;
  font-size: 1.4rem;
  border-radius: .8rem;
  margin-top: .5rem;
  width: fit-content;
`;
const ThemedTextarea = styled.textarea`
  border: .1rem solid #d4d4d4;
  display: inline-block;
  padding: 1.2rem 2.4rem;
  font-size: 1.4rem;
  border-radius: .8rem;
  margin-top: .5rem;
  width: 100%;
`;

const ThemedInputDiv = styled.div`
  border: .1rem solid #d4d4d4;
  display: inline-block;
  padding: 1.2rem 2.4rem;
  font-size: 1.4rem;
  border-radius: .8rem;
  margin-top: .5rem;
  width: 100%;
`;

const MediumHeader = styled.h2`
  display: flex;
  justify-content: space-around;
  font-size: 2.4rem;
  font-family: "Lato";
  font-weight: 900;
`;
const LargeHeader = styled.h1`
  display: flex;
  justify-content: space-around;
  font-size: 3.2rem;
  font-family: "Lato";
  font-weight: 900;
`;
const LargeCardHeader = styled.h1`
  font-size: 3.2rem;
  font-family: "Inter";
  font-weight: 800;
`;
const LargeDashboardHeader = styled.h3`
  font-size: 2rem;
  font-family: "Lato";
  font-weight: 700;
`;
const MediumDashboardHeader = styled.h3`
  font-size: 1.6rem;
  font-family: "Lato";
  font-weight: 700;
`;
const SmallDashboardHeader = styled.h6`
  font-size: 1.4rem;
  color: #a0a0a0;
  font-family: "Lato";
  font-weight: 400;
`;
const BoldCardSmallText = styled.span`
  font-size: 1.2rem;
  font-family: "Jost";
  font-weight: 600;
`;
const MediumWalletHeader = styled.h3`
  font-size: 1.8rem;
  font-family: "Mulish";
  font-weight: 500;
`;
const TableContentText = styled.span`
  font-size: 1.2rem;
  font-family: "Lato";
  font-weight: 400;
`;

const WalletListText = styled.span`
  font-size: 1.2rem;
  font-family: "Open Sans";
  font-weight: 400;
  color:#6556D9;
`;
const WalletListButtonText = styled.span`
  font-size: 1.2rem;
  font-family: "Mulish";
  font-weight: 400;
  color:#898989;
`;

const ProductSizeWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ProductSizeText = styled.div`
  height: 3rem;
  width: 3rem;
  font-size: 1.4rem;
  font-family: 'Lato';
  font-weight: 400;
  /* border:none; */
  border-radius: .6rem; 
  color: #57636F;
  background-color: #F6F6F7;
  text-align:center;
  line-height:3rem;
  cursor:pointer;
  &:hover {
    border: .1rem solid #26264C
  }
  &.active {
  color: #F6F6F7;
  background-color: #26264C;
 
`;
const TableContentBoldText = styled.p`
  font-size: 1.2rem;
  font-family: "Helvetica";
  font-weight: 600;
`;
const TableContentGreyText = styled.span`
  font-size: 1.2rem;
  font-family: "Lato";
  font-weight: 400;
  color: #5a5a76;
`;
const ListCardSmallText = styled.span`
  font-size: 1.2rem;
  font-family: "Lato";
  font-weight: 400;
`;
const MediumParagraph = styled.p`
  font-family: "Lato";
  font-weight: 400;
  font-size: 1.6rem;
`;
const ThemedLabel = styled.label`
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
`;
const ThemeCardWrapper = styled.div`
  /* display:grid;
grid-template-columns: 14rem 14rem; */
`;
const ThemeCalendarWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;
const ThemeCalendarItem = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  text-align: center;
`;
const StoreProductCard = styled.div`
  box-shadow: 0rem .4rem .4rem 0rem #89898900;
`;

export {
  InfoCardWrapper,
  InfoCardCircle,
  InfoCardNumber,
  InfoCardTitle,
  InfoCardBody,
  IntroButton,
  IntroTitle,
  IntroBody,
  SellerSectionTitle,
  SellerSectionBody,
  SellerSectionButton,
  AboutCardTitle,
  AboutCardBody,
  SendMailButton,
  AboutCardContainer,
  ThemedButton,
  ThemedInput,
  MediumHeader,
  ThemedLabel,
  MediumParagraph,
  AuthPageWrapper,
  LargeHeader,
  ThemeCardWrapper,
  ThemedInputDiv,
  ThemedTextarea,
  ThemedCancelButton,
  LargeCardHeader,
  BoldCardSmallText,
  ListCardSmallText,
  LargeDashboardHeader,
  SmallDashboardHeader,
  TableContentGreyText,
  TableContentText,
  TableContentBoldText,
  ProductSizeWrapper,
  ProductSizeText,
  ThemedRectangleInput,
  GridContainer,
  ThemedDivInput,
  ThemedDivNumberInput,
  ThemedEditButton,
  MediumDashboardHeader,
  StoreProductCard,
  MediumWalletHeader,
  ThemedWalletButton,
  ThemedWalletTaskButton,
  ThemeCalendarWrapper,
  ThemeCalendarItem,
  WalletListText,
  WalletListButtonText
};
