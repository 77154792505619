import React from 'react'
import UploadImageIcon from '../../assets/icons/UploadImageIcon'
import {
  ThemedInput,
  ThemedLabel,
  ThemedDivInput,
} from '../../assets/styles/styles'

export default function FormFileInputWithLabel(props) {
  const { name, placeholder, label, type, value, setValue } = props
  return (
    <div className="mb-3">
      <p> {label}</p>
      {label && (
        <ThemedLabel htmlFor={`${name}`} className="w-100">
          <ThemedDivInput className="w-100 d-flex justify-content-between">
            <span style={{ color: '#d4d4d4' }}> {placeholder}</span>{' '}
            <UploadImageIcon />
          </ThemedDivInput>
          <ThemedInput
            className="d-none"
            id={`${name}`}
            type={`${type}`}
            placeholder={`${placeholder}`}
            value={value}
            onChange={(e) => setValue && setValue(e.target.value)}
          />
        </ThemedLabel>
      )}
 
    </div>
  )
}
