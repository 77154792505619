import React from 'react'
import { ThemedTextarea, ThemedLabel } from '../../assets/styles/styles'

export default function TextAreaWithLabel(props) {
  const { name, placeholder, label, type, value, setValue } = props
  return (
    <div className="mb-3">
      {label && (
        <ThemedLabel htmlFor={`${name}`} className="">
          {label}
        </ThemedLabel>
      )}

      <ThemedTextarea
        name={`${name}`}
        className="w-100"
        placeholder={`${placeholder}`}
        value={value}
        onChange={(e) => setValue && setValue(e.target.value)}
      />
    </div>
  )
}
